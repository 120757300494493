<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：货品配置
开始时间：2021-08-25
开发人员：康泽鑫
最后修改：2021-08-25
备注说明：如需修改请联系开发人员
-->
<template>
  <div class="setting_itemBox" id="upload_setting_Box">
    <!-- 配置内容 -->
    <div class="setting_contentBox billing_table" v-if="isPower">
      <el-table id="classifyTable" :data="productSettingData" :span-method="objectSpanMethod" height="100%" border
        style="width: 100%">
        <el-table-column prop="name" sortable label="参数名称" width="400"></el-table-column>
        <el-table-column prop="setting" label="参数设置" width="500">
          <template slot-scope="scope">
            <span v-if="scope.row.isInput">
              <el-input v-model="scope.row.para_value" placeholder="请输入文件大小" size="mini" :disabled="isDisabled"
                onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>{{scope.row.unit}}
            </span>
            <el-radio-group v-if="scope.row.isRadio" v-model="scope.row.para_value" @change="updateParam(scope.row)"
              :disabled="isDisabled">
              <el-radio label="0">启用</el-radio>
              <el-radio label="1">不启用</el-radio>
            </el-radio-group>
            <!-- 货品编码自动生成过滤分类 -->
            <span @click="showProductTypeBox" v-if="scope.row.isFilterProType && scope.row.para_value == 0" class="per">
              排除分类:
              <span v-if="filterProductTypeArr.length>0">{{filterProductTypeArr.join(",")}}</span>
              <span v-if="filterProductTypeArr.length==0">未指定</span>
            </span>
            <!-- 货品审核人 -->
            <span @click="selectEmployee(0)" class="per"
              v-if="scope.row.isStaffChoose && scope.row.para_value == 0">
              当前审核员工:
              <span v-if="!!auditName">{{auditName}}</span>
              <span v-if="auditName == ''">未指定</span>
            </span>
            <!-- 货品导出权限人 -->
            <span @click="selectEmployee(1)" class="per"
              v-if="scope.row.isproductChoose && scope.row.para_value == 0">
              当前货品导出权限员工:
              <span v-if="!!productName">{{productName}}</span>
              <span v-if="productName == ''">未指定</span>
            </span>
            <!-- 弹出框(货品属性过滤值) -->
            <div v-if="scope.row.isPopover" class="popover">
              <span>
                <el-checkbox v-model="scope.row.item[0].para_value" @change="updateParam(scope.row,0)"
                  :disabled="isDisabled">
                  {{scope.row.item[0].name}}
                </el-checkbox>
              </span>
              <span>
                <span class="attr">{{scope.row.item[1].name}}：</span>
                <el-popover placement="right-start" width="180" trigger="click"
                  :disabled="!scope.row.item[0].para_value">
                  <el-checkbox-group v-model="scope.row.item[1].para_value">
                    <el-checkbox v-for=" item in attrData" :key="item.id+''" :value="item.id+''" :label="item.id+''">
                      {{item.name}}
                    </el-checkbox>
                  </el-checkbox-group>
                  <el-button size="mini" type="primary" style="float: right" @click="updateParam(scope.row,1)"
                    :disabled="isDisabled">确定
                  </el-button>
                  <span slot="reference"
                    :class="scope.row.item[0].para_value? 'choose':'choose disabled'">{{getAttrName(scope.row) || '请选择'}}</span>
                </el-popover>
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="配置说明">
          <template slot-scope="scope">
            <span class="remark">{{scope.row.remark}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 负责人选择组件 -->
    <personnel-list @SelectedData="getPersonnelData" :defaultSelected="employeeOperation==0?defaultSelected:defaultProductSelected"></personnel-list>

    <!-- 货品分类弹框组件 -->
    <product-type @SelectedData="getProductData"></product-type>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入方法
  import api from "@/network/system/controls";
  import attrApi from '@/network/system/dictionary';

  //导入组件
  import personnelList from '@/components/commComponent/list/list_personnel'; //负责人列表组件
  import productType from '@/components/commComponent/tree/tree_productType'; //货品分类组件

  export default {
    name: 'setting_product',
    data() {
      return {
        isDisabled: true, //是否有修改权限
        isPower: false, //是否有查询权限
        loading: '', //loading框
        oldValue: '', //编辑前数据
        auditName: '', //员工信息
        productName:'',//货品权限导出员工信息
        employeeOperation:'',//判断是审核人操作还是货品导出权限操作
        filterProductTypeIdArr: [], //货品自动生成编码过滤分类(id)
        filterProductTypeArr: [], //货品自动生成编码过滤分类(名称)
        filterProductTypeArrOld: [], //货品自动生成编码过滤分类(名称- 修改前的值)
        filterProductTypeObj: {
          name: '货品编码自动生成过滤的货品分类',
          para_value: '',
        }, //货品自动生成编码过滤分类对象
        auditObj: { //审核人配置信息
          name: '审核人',
          para_value: '',
        },
        productObj: { //货品导出权限配置信息
          name: '货品导出权限员工',
          para_value: '',
        },
        defaultSelected: [], //审核人默认勾选
        defaultProductSelected:[],//导出权限员工
        attrData: [], //属性列表
        productSettingData: [{
            name: '手动填写货品编码', //参数名称
            isInput: false, //是否是输入框
            isRadio: true, //是否单选钮
            isFilterProType: true, //是否为手动货品编码过滤分类
            checked: false, //多选钮的值
            para_value: '0', //参数设置
            unit: '', //参数单位
            remark: '每月只能启用一次，本月生效必须没有不同方式编号新的货品产生，否则，只下月才能生效', //配置说明
          }, {
            name: '商品序列号', //参数名称
            isInput: false, //是否是输入框
            isRadio: true, //是否单选钮
            checked: false, //多选钮的值
            para_value: '0', //参数设置
            unit: '', //参数单位
            remark: '用序列号作为商品的唯一性标识，启用商品序列号支持可以帮助您跟踪和管理商品', //配置说明
          }, {
            name: '原物料编码', //参数名称
            isInput: false, //是否是输入框
            isRadio: true, //是否单选钮
            checked: false, //多选钮的值
            para_value: '0', //参数设置
            unit: '', //参数单位
            remark: '', //配置说明
          }, {
            name: '审核人', //参数名称
            isInput: false, //是否是输入框
            isRadio: true, //是否单选钮
            isStaffChoose: true, //是否员工选择
            checked: false, //多选钮的值
            para_value: '0', //参数设置
            unit: '', //参数单位
            remark: '', //配置说明
          }, {
            name: '货品导出权限', //参数名称
            isInput: false, //是否是输入框
            isRadio: true, //是否单选钮
            isproductChoose: true, //是否员工选择
            checked: false, //多选钮的值
            para_value: '0', //参数设置
            unit: '', //参数单位
            remark: '', //配置说明
          }, {
            name: '必填项', //参数名称
            isInput: false, //是否是输入框
            isRadio: false, //是否单选钮
            isPopover: true, //是否弹出框
            checked: false, //多选钮的值
            remark: '', //配置说明
            item: [{
              name: '销售价',
              para_value: [], //参数设置
            }, {
              name: '属性',
              para_value: '', //参数设置
            }]
          }, {
            name: '必填项', //参数名称
            isInput: false, //是否是输入框
            isRadio: false, //是否单选钮
            isPopover: true, //是否弹出框
            checked: false, //多选钮的值
            remark: '', //配置说明
            item: [{
              name: '采购价',
              para_value: '', //参数设置
            }, {
              name: '属性',
              para_value: '', //参数设置
            }]
          }, {
            name: '必填项', //参数名称
            isInput: false, //是否是输入框
            isRadio: false, //是否单选钮
            isPopover: true, //是否弹出框
            checked: false, //多选钮的值
            remark: '', //配置说明
            item: [{
              name: '规格信息',
              para_value: '', //参数设置
            }, {
              name: '属性',
              para_value: '', //参数设置
            }]
          }, {
            name: '必填项', //参数名称
            isInput: false, //是否是输入框
            isRadio: false, //是否单选钮
            isPopover: true, //是否弹出框
            checked: false, //多选钮的值
            remark: '', //配置说明
            item: [{
              name: '重量',
              para_value: '', //参数设置
            }, {
              name: '属性',
              para_value: '', //参数设置
            }]
          }, {
            name: '必填项', //参数名称
            isInput: false, //是否是输入框
            isRadio: false, //是否单选钮
            isPopover: true, //是否弹出框
            checked: false, //多选钮的值
            remark: '', //配置说明
            item: [{
              name: '起订量',
              para_value: '', //参数设置
            }, {
              name: '属性',
              para_value: '', //参数设置
            }]
          }, {
            name: '必填项', //参数名称
            isInput: false, //是否是输入框
            isRadio: false, //是否单选钮
            isPopover: true, //是否弹出框
            checked: false, //多选钮的值
            remark: '', //配置说明
            item: [{
              name: '供应商',
              para_value: '', //参数设置
            }, {
              name: '属性',
              para_value: '', //参数设置
            }]
          }, {
            name: '必填项', //参数名称
            isInput: false, //是否是输入框
            isRadio: false, //是否单选钮
            isPopover: true, //是否弹出框
            checked: false, //多选钮的值
            remark: '', //配置说明
            item: [{
              name: '上传图片',
              para_value: '', //参数设置
            }, {
              name: '属性',
              para_value: '', //参数设置
            }]
          }, {
            name: '必填项', //参数名称
            isInput: false, //是否是输入框
            isRadio: false, //是否单选钮
            isPopover: true, //是否弹出框
            checked: false, //多选钮的值
            remark: '', //配置说明
            item: [{
              name: '货品简介',
              para_value: '', //参数设置
            }, {
              name: '属性',
              para_value: '', //参数设置
            }]
          }, {
            name: '必填项', //参数名称
            isInput: false, //是否是输入框
            isRadio: false, //是否单选钮
            isPopover: true, //是否弹出框
            checked: false, //多选钮的值
            remark: '', //配置说明
            item: [{
              name: '货品详情',
              para_value: '', //参数设置
            }, {
              name: '属性',
              para_value: '', //参数设置
            }]
          },
          {
            name: '必填项', //参数名称
            isInput: false, //是否是输入框
            isRadio: false, //是否单选钮
            isPopover: true, //是否弹出框
            checked: false, //多选钮的值
            remark: '', //配置说明
            item: [{
              name: '库存期初',
              para_value: '', //参数设置
            }, {
              name: '属性',
              para_value: '', //参数设置
            }]
          }
        ]
      }
    },
    computed: {
      ...mapState({
        personnelData: state => state.hr.personnelData, //企业所有员工信息
        ProductClassfiy: state => state.system.ProductClassfiy, //货品分类数据
        userPowerInfo: state => state.user.userPowerInfo, //权限
      })
    },
    created() {
    },
    mounted() {
      //获取界面数据
      this.initVuexData();
    },
    methods: {
      ...mapMutations({
        getCompanyParamList: 'getCompanyParamList',
      }),
      ...mapMutations([
        'SHOW_PERSONNELBOX', //控制显示员工弹框
        'SHOW_PRODUCTTYPEBOX', //控制显示货品分类弹框
      ]),
      ...mapActions([
        'getAllUserData', //请求企业用户数据
        'getUserPowerInfo', //获取用户操作权限
        'getProductClassfiy',//获取货品分类数据
      ]),

      /* 初始化界面数据 */
      async initVuexData(){
        if(this.ProductClassfiy.length == 0){
          await this.getProductClassfiy();
        }

        //获取货品配置参数
        this.getProductSetting();
        //获取属性参数
        this.getAttrData();
      },
      // 区分审核人操作和货品导出操作
      selectEmployee(type){
        this.employeeOperation=type
        this.SHOW_PERSONNELBOX(true)
      },
      /* 获取货品配置信息 */
      async getProductSetting() {
        //判断企业用户
        if (this.personnelData.length == 0) {
          this.getAllUserData();
        }
        //判断用户权限(需优化  这里先这样写  不知道为什么await没有用)
        if (this.userPowerInfo.length == 0) {
          await this.getUserPowerInfo();
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 2, 1, '查询')) {
            return
          }
          this.isPower = true
        } else {
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 2, 1, '查询')) {
            return
          }
          this.isPower = true
        }
        this.oldValue = '' //清空编辑前数据
        //发送请求
        api.getAllcompanyInfo().then(res => {
          if (res.code == 200) {
            //判断是否有修改权限
            if (this.commonJsExtend.isHaveThePower(22, 2, 4, '修改', 0)) {
              return
            }
            this.isDisabled = false;

            //处理数据
            let productData = [] //定义带属性的数组
            let attrIndex = 0 //带属性值对象的索引
            let num = 5 //定义跳过num个进行赋值
            res.data.forEach(itemI => { //循环返回结果
              let id = 302 //定义第一个id值
              let paraIds = [301, 302, 345, 347,371] //定义不带属性的参数id数组
              let attrParaIds = [] //定义带属性的参数id数组
              for (let i = 0; i < 20; i++) {
                id++
                attrParaIds.push(id) //必填项参数id数组
              }
              if (itemI.para_id == '348') { //审核人  单独处理
                //获取信息
                this.$set(this.auditObj, 'id', itemI.id); //id
                this.$set(this.auditObj, 'para_id', itemI.para_id); //参数id
                this.$set(this.auditObj, 'operate_time', itemI.operate_time); //操作时间
                this.$set(this.auditObj, 'owner_id', itemI.owner_id); //适用对象id
                this.$set(this.auditObj, 'owner_type', itemI.owner_type); //适用对象类型
                this.$set(this.auditObj, 'old_val', ""); //旧值
                //获取用户
                this.personnelData.forEach(itemL => {
                  if (itemL.user_id == itemI.para_value) {
                    this.auditName = itemL.user_name;
                    this.auditObj.para_value = itemL.user_id;
                    this.$set(this.auditObj, 'old_val', itemL.user_name); //旧值
                    this.defaultSelected.push(itemL.user_id);
                  }
                })
              }
              if (itemI.para_id == '372') { //货品权限员工  单独处理
                 let paraValue=itemI.para_value.split(",");
                //获取信息
                this.$set(this.productObj, 'id', itemI.id); //id
                this.$set(this.productObj, 'para_id', itemI.para_id); //参数id
                this.$set(this.productObj, 'operate_time', itemI.operate_time); //操作时间
                this.$set(this.productObj, 'owner_id', itemI.owner_id); //适用对象id
                this.$set(this.productObj, 'owner_type', itemI.owner_type); //适用对象类型
                this.$set(this.productObj, 'old_val', ""); //旧值
                let productExportName=[]
                let productValue=[]

                //获取用户
                this.personnelData.forEach(itemL => {
                  if (paraValue.includes(itemL.user_id.toString())) {
                    productExportName.push(itemL.user_name)
                    productValue.push(itemL.user_id)
                    this.defaultProductSelected.push(itemL.user_id);
                  }
                })
                this.productName = productExportName.join(",");
                this.productObj.para_value = productValue.join(",");
                this.$set(this.productObj, 'old_val', productExportName.join(",")); //旧值
              }
              //判断是否为货品编码生成过滤分类
              if (itemI.para_id == '368') {
                //获取信息
                this.$set(this.filterProductTypeObj, 'id', itemI.id); //id
                this.$set(this.filterProductTypeObj, 'para_id', itemI.para_id); //参数id
                this.$set(this.filterProductTypeObj, 'operate_time', itemI.operate_time); //操作时间
                this.$set(this.filterProductTypeObj, 'owner_id', itemI.owner_id); //适用对象id
                this.$set(this.filterProductTypeObj, 'owner_type', itemI.owner_type); //适用对象类型
                this.$set(this.filterProductTypeObj, 'para_value', ''); //旧值
                this.$set(this.filterProductTypeObj, 'old_val', itemI.para_value); //旧值

                //处理前清空
                this.filterProductTypeArr = [];
                this.filterProductTypeIdArr = [];
                this.filterProductTypeArrOld = [];

                //获取选中值
                if (!!itemI.para_value) {
                  //获取非树形结构
                  let noTree = this.commonJsExtend.toNoTree(this.ProductClassfiy,[]);

                  //处理数据
                  noTree.forEach((itemK, indexK) => {
                    if (itemI.para_value.indexOf(itemK.id) != -1) {
                      this.filterProductTypeArr.push(itemK.name);
                      this.filterProductTypeIdArr.push(itemK.id);
                      //将旧值存入
                      this.filterProductTypeArrOld.push(itemK.name);
                    }
                  })
                }
              }
              paraIds.forEach((itemJ, indexJ) => { //循环不带属性的参数id数组
                if (itemI.para_id == itemJ) { //匹配对应对象
                  this.productSettingData[indexJ].para_value = itemI.para_value; //参数值
                  this.$set(this.productSettingData[indexJ], 'id', itemI.id); //参数id
                  this.$set(this.productSettingData[indexJ], 'para_id', itemI.para_id); //参数id
                  this.$set(this.productSettingData[indexJ], 'operate_time', itemI.operate_time); //操作时间
                  this.$set(this.productSettingData[indexJ], 'owner_id', itemI.owner_id); //适用对象id
                  this.$set(this.productSettingData[indexJ], 'owner_type', itemI.owner_type); //适用对象类型
                  this.$set(this.productSettingData[indexJ], 'old_val', itemI.para_value); //旧值
                  this.oldValue += itemI.para_value + '##';
                }
              })
              attrParaIds.forEach((itemK, indexK) => { //循环带属性的参数id数组
                if (itemI.para_id == itemK) { //匹配对应对象
                  if (attrIndex) {
                    this.productSettingData[indexK + num].item[attrIndex].para_value = itemI.para_value
                      .split(',') //参数值
                    this.$set(this.productSettingData[indexK + num].item[attrIndex], 'old_val', itemI
                      .para_value.split(',')) //旧值
                  } else {
                    this.productSettingData[indexK + num].item[attrIndex].para_value = (itemI
                      .para_value ==
                      0 ? true : false) //参数值
                  }
                  this.$set(this.productSettingData[indexK + num].item[attrIndex], 'id', itemI.id) //参数id
                  this.$set(this.productSettingData[indexK + num].item[attrIndex], 'para_id', itemI
                    .para_id) //参数id
                  this.$set(this.productSettingData[indexK + num], 'operate_time', itemI
                    .operate_time) //操作时间
                  this.$set(this.productSettingData[indexK + num], 'owner_id', itemI.owner_id) //适用对象id
                  this.$set(this.productSettingData[indexK + num], 'owner_type', itemI
                    .owner_type) //适用对象类型
                  this.oldValue += itemI.para_value + '##'
                  if (attrIndex == 0) { //切换attrIndex的值
                    attrIndex = 1 //切换为第二个数组
                    num--
                  } else {
                    attrIndex = 0 //切换为第一个数组
                  }
                }
              })
            })
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 修改参数 type => 0:必填项 1:属性*/
      updateParam(data, type) {
        //定义所需参数
        let logContent = ''; //日志内容
        let isRadioParaIds = [301, 302, 345, 347,371]; //单选钮配置项

        //判断是否为启用/未启用类型
        if (isRadioParaIds.indexOf(data.para_id) != -1) { //只有启用跟未启用时
          logContent =
            `修改了【货品配置】中的【${data.name}】,修改前:${data.old_val==0?'启用':'不启用'},修改后:${data.para_value==0?'启用':'不启用'}` //拼接日志
        } else if (data.para_id == '368') { //货品自动生成编码过滤分类
          logContent =
            `修改了【货品配置】中的【${data.name}】,修改前:${this.filterProductTypeArrOld.join(',')},修改后:${this.filterProductTypeArr.join(',')}`; //拼接日志
        } else { //属性项
          if (type == 0) {
            logContent = `${data.item[0].para_value?'启用':'关闭'}了【货品配置】中的【${data.item[0].name}】` //拼接日志
          } else if (type == 1) {
            let oldAttrName = [],
              newAttrName = []
            data.item[1].old_val.forEach(itemI => {
              this.attrData.forEach(itemJ => {
                if (itemI == itemJ.id) {
                  oldAttrName.push(itemJ.name)
                }
              })
            })
            data.item[1].para_value.forEach(itemI => {
              this.attrData.forEach(itemJ => {
                if (itemI == itemJ.id) {
                  newAttrName.push(itemJ.name)
                }
              })
            })
            logContent =
              `修改了【货品配置】中【${data.item[0].name}】的【${data.item[1].name}】,修改前:${oldAttrName},修改后:${newAttrName}` //拼接日志
          }
        }
        let submitData = this.getNewValue().submitData;
        if (type == 0) { //必填项
          data = submitData.filter(item => item.para_id == data.item[0].para_id)[0]
        } else if (type == 1) { //属性
          data = submitData.filter(item => item.para_id == data.item[1].para_id)[0]
        }
        //获取日志信息
        data.logContent = logContent;
        //发送请求
        api.updateSysParametersValue(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '修改参数配置成功'
            })
            this.getProductSetting()
            this.getCompanyParamList() //刷新vuex
          } else {
            this.$message({
              type: 'error',
              message: res.message
            })
          }
        })
      },

      /* 获取修改后数据 */
      getNewValue() {
        let newValue = '' //编辑后参数
        let productSettingData = JSON.parse(JSON.stringify(this.productSettingData)) //转存页面数据
        let submitData = [] //定义提交数据数组
        productSettingData.forEach((item, index) => {
          if (index <= 4) { //没有属性值的前五项
            newValue += '##' + item.para_value //拼接
            submitData.push(item) //将符合条件的对象存进提交数据数组
          } else { //有属性值的项
            let paraValue = item.item[0].para_value
            if (paraValue) {
              newValue += '##0' //拼接
              item.item[0].para_value = 0
            } else {
              newValue += '##1' //拼接
              item.item[0].para_value = 1
            }
            newValue += '##' + item.item[1].para_value.toString() //拼接
            item.item[1].para_value = item.item[1].para_value.toString() //拼接
            submitData.push(item.item[0]) //将符合条件的对象存进提交数据数组
            submitData.push(item.item[1]) //将符合条件的对象存进提交数据数组
          }
        })
        return {
          newValue,
          submitData
        }
      },

      /* 修改货品配置信息 */
      updateData() {
        let newValue = this.getNewValue().newValue
        let submitData = this.getNewValue().submitData
        let oldvalue = this.oldValue + 'G' + newValue //日志拼接
        //准备后端参数
        var data = {
          oldvalue,
          sSysParamatersValue: JSON.stringify(submitData),
        }
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#upload_setting_Box", 4, '数据加载中,请稍后...');
        api.updateProductParame(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '参数修改成功!',
              duration: this.elDuration
            })
            this.getProductSetting() //刷新数据
            this.getCompanyParamList() //刷新vuex
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取属性列表 */
      getAttrData(row) {
        // 发送请求
        attrApi.productDict.findAllProductProperty().then(res => {
          if (res.code == 200) {
            this.attrData = res.data //属性列表
          } else {
            this.$message({
              type: "warning",
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取选择的负责人的信息 */
      getPersonnelData(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 2, 4, '修改')) {
          return
        }
        this.defaultSelected = [];
        this.defaultProductSelected=[];
        if(this.employeeOperation==0){
          if (data.length > 1) {
            this.$message({
              type: 'error',
              message: '最多选择一个审核人'
            })
            return false
          }

          //判断是否选择数据
          if (data.length == 0) {
            this.auditName = "";
            this.auditObj.para_value = "";
          } else {
            this.auditName = data[0].user_name;
            this.auditObj.para_value = data[0].user_id;
            this.defaultSelected.push(data[0].user_id);
          }

          this.updateParam(this.auditObj);
        }
        else if(this.employeeOperation==1){
          console.log(data)
          //判断是否选择数据
          if (data.length == 0) {
            this.productName = "";
            this.productObj.para_value = "";
          } else {
            let userName=[]
            let paraValue=[]
            data.forEach((item,index)=>{
              userName.push(item.user_name)
              paraValue.push(item.user_id)
              this.defaultProductSelected.push(item.user_id);
            })
            this.productName = userName.join(",")
            this.productObj.para_value = paraValue.join(",");

          }

          this.updateParam(this.productObj);
        }

      },

      /* 显示货品分类弹框 */
      showProductTypeBox() {
        //显示弹框
        this.SHOW_PRODUCTTYPEBOX(true);
      },

      /* 获取货品分类弹框选择的数据 */
      getProductData(data) {
        //循环前清空
        this.filterProductTypeArr = [];
        this.filterProductTypeIdArr = [];

        //判断是否有数据
        if (data.length > 0) {
          //循环获取id数组
          data.forEach((item, index) => {
            this.filterProductTypeArr.push(item.name);
            this.filterProductTypeIdArr.push(item.id);
          })
        }

        //获取修改后的值
        this.filterProductTypeObj.para_value = this.filterProductTypeIdArr.join(",");

        //调用修改参数方法
        this.updateParam(this.filterProductTypeObj);
      },

      /* 获取属性名称 */
      getAttrName(row) {
        let attrNameStr = []
        let val = row.item[1].para_value
        if (val) {
          this.attrData.forEach(itemI => {
            val.forEach(itemJ => {
              if (itemI.id == itemJ) {
                attrNameStr.push(itemI.name)
              }
            })
          })
        }
        return attrNameStr.toString()
      },

      /* 表格合并单元格 */
      getJBXXTableData() {
        let spanOneArr = [],
          spanTwoArr = [],
          concatOne = 0,
          concatTwo = 0;
        this.productSettingData.forEach((item, index) => {
          if (index === 0) {
            spanOneArr.push(1);
            spanTwoArr.push(1);
          } else {
            if (item.name === this.productSettingData[index - 1].name) { //第一列需合并相同内容的判断条件
              spanOneArr[concatOne] += 1;
              spanOneArr.push(0);
            } else {
              spanOneArr.push(1);
              concatOne = index;
            };
          }
        });
        return {
          one: spanOneArr,
          two: spanTwoArr
        }
      },

      /* 前行row、当前列column、当前行号rowIndex、当前列号columnIndex */
      objectSpanMethod({
        row,
        column,
        rowIndex,
        columnIndex
      }) {
        if (columnIndex === 0) {
          const _row = (this.getJBXXTableData(this.productSettingData).one)[rowIndex];
          const _col = _row > 0 ? 1 : 0;
          return {
            rowspan: _row,
            colspan: _col
          };
        }
      },
    },
    components: {
      personnelList,
      productType
    },
  }
</script>

<style lang="less" scoped>
  .button {
    margin-bottom: 5px;
  }

  .popover {
    display: flex;
    justify-content: space-between;
    width: 200px;
  }

  .attr {
    padding-left: 20px;
  }

  .choose {
    color: #409EFF;

    &:hover {
      cursor: pointer;
    }
  }

  .disabled:hover {
    cursor: not-allowed;
  }

  .remark {
    color: red;
    white-space: pre-line
  }

  .per {
    color: #0099FF;
    font-size: 14px;
    cursor: pointer;
    padding: 0 10px 3px 10px;
  }
</style>
