<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：单据配置
开始时间：2021-08-25
开发人员：康泽鑫
最后修改：2021-08-25
备注说明：如需修改请联系开发人员
-->
<template>
  <div class="setting_itemBox">
    <!-- 设置内容 -->
    <div class="setting_contentBox billing_table" v-if="isPower">
      <el-table id="classifyTable" :span-method="objectJMethod" :data="data_settingProduction" height="100%" border
        style="width: 100%">
        <el-table-column prop="type" label="配置类型" width="150"></el-table-column>
        <el-table-column prop="name" sortable label="参数名称" width="300"></el-table-column>
        <el-table-column prop="setting" label="参数设置" width="800">
          <template slot-scope="scope">
            <el-radio v-for="(item,index) in scope.row.setting" v-model="scope.row.para_value" :label="index"
              :disabled="isDisabled" @change="updateParam(scope.row)">{{item.name}}
            </el-radio>
            <!-- bom审核设置员工 -->
            <span @click="showUserBox(2)" class="per" v-if="scope.row.state == 2 && scope.row.para_value == 0">当前审核员工:
              {{auditName}}</span>
              <!-- bom导出设置员工 -->
              <span @click="showUserBox(15)" class="per" v-if="scope.row.state == 16 && scope.row.para_value == 0">当前审核员工:
                {{bomExportName}}</span>
            <!-- 客户授信自动结存时间 -->
            <span v-if="scope.row.is_input == true" style="width: 90px;">每月 <el-input-number :disabled="isDisabled"
                v-model="scope.row.para_value" :min="1" :max="31" controls-position="right" size="small" width="50"
                @change="updateParam(scope.row)">
              </el-input-number> 号</span>
            <!-- 出入库单相关设置 -->
            <!-- 入库凭质检单开启后设置 -->
            <span v-if="scope.row.state == 3 && scope.row.para_value == 0" style="width: 90px;">
              <el-checkbox :disabled="isDisabled" v-model="scope.row.is_check" @change="updateParam(scope.row,1)">
                仅指定采购入库单</el-checkbox>
            </span>
            <!-- 出库发货凭质检单开启后设置 -->
            <span v-if="scope.row.state == 4 && scope.row.para_value == 0" style="width: 90px;">
              <el-checkbox :disabled="isDisabled" v-model="scope.row.is_check" @change="updateParam(scope.row,1)">
                仅指定销售出库单</el-checkbox>
            </span>
            <!-- 入库凭订单开启后设置 -->
            <span v-if="scope.row.state == 9 && scope.row.para_value == 0" style="width: 90px;">
              <el-checkbox :disabled="isDisabled" v-model="scope.row.is_check" @change="updateParam(scope.row,1)">
                仅指定采购入库单</el-checkbox>
            </span>
            <!-- 出库发货凭订单开启后设置 -->
            <span v-if="scope.row.state == 10 && scope.row.para_value == 0" style="width: 90px;">
              <el-checkbox :disabled="isDisabled" v-model="scope.row.is_check" @change="updateParam(scope.row,1)">
                仅指定销售出库单</el-checkbox>
            </span>
            <!-- 信用配置时间 -->
            <span @click="showUserBox(20)" class="per" v-if="scope.row.state == 20 &&  scope.row.para_value == 0">:
              {{creditAllocationCode}}</span>
              <span v-if="scope.row.state == 20 &&  scope.row.para_value == 0" style="width: 90px; padding-left:10px;">
                <el-checkbox :disabled="isDisabled" v-model="scope.row.is_check" @change="updateParam(scope.row,1)">
                  所有销售出库单</el-checkbox>
              </span>

            <!-- 成本核算相关配置 -->
            <span v-if="scope.row.state == 30" style="width: 90px; padding-left:10px;">
              <el-checkbox :disabled="isDisabled" v-model="scope.row.is_check" @change="updateParam(scope.row,1)">
                物料费用</el-checkbox>
            </span>
            <span v-if="scope.row.state == 31" style="width: 90px; padding-left:10px;">
              <el-checkbox :disabled="isDisabled" v-model="scope.row.is_check" @change="updateParam(scope.row,1)">
                预计物料费用</el-checkbox>
            </span>
            <span v-if="scope.row.state == 32" style="width: 90px; padding-left:10px;">
              <el-checkbox :disabled="isDisabled" v-model="scope.row.is_check" @change="updateParam(scope.row,1)">
                物料加工</el-checkbox>
            </span>
            <span v-if="scope.row.state == 33" style="width: 90px; padding-left:10px;">
              <el-checkbox :disabled="isDisabled" v-model="scope.row.is_check" @change="updateParam(scope.row,1)">
                人工费</el-checkbox>
            </span>
            <span v-if="scope.row.state == 34" style="width: 90px; padding-left:10px;">
              <el-checkbox :disabled="isDisabled" v-model="scope.row.is_check" @change="updateParam(scope.row,1)">
                管理费</el-checkbox>
            </span>
            <span v-if="scope.row.state == 35" style="width: 90px; padding-left:10px;">
              <el-checkbox :disabled="isDisabled" v-model="scope.row.is_check" @change="updateParam(scope.row,1)">
                制造费</el-checkbox>
            </span>
            <span v-if="scope.row.state == 36" style="width: 90px; padding-left:10px;">
              <el-checkbox :disabled="isDisabled" v-model="scope.row.is_check" @change="updateParam(scope.row,1)">
                其它费用</el-checkbox>
            </span>
            <span v-if="scope.row.state == 37" style="width: 90px; padding-left:10px;">
              <el-checkbox :disabled="isDisabled" v-model="scope.row.is_check" @change="updateParam(scope.row,1)">
                报销费用</el-checkbox>
            </span>
            <span v-if="scope.row.state == 38" style="width: 90px; padding-left:10px;">
              <el-checkbox :disabled="isDisabled" v-model="scope.row.is_check" @change="updateParam(scope.row,1)">
                报废数也计成本</el-checkbox>
            </span>
            <!-- alibaba订单相关设置 -->
            <span @click="showUserBox(5)" class="per" v-if="scope.row.state == 5">指定员工: {{alibabaUserName}}</span>
            <span @click="showCustomerBox(6)" class="per" v-if="scope.row.state == 6">指定客户:
              {{alibabaCustomerName}}</span>
            <span @click="showProductBox(14)" class="per" v-if="scope.row.state == 14">指定货品:
              {{alibabaProductCode}}</span>
            <!-- 1688订单相关设置 -->
            <span @click="showUserBox(7)" class="per" v-if="scope.row.state == 7">指定员工: {{ali1688UserName}}</span>
            <span @click="showCustomerBox(8)" class="per" v-if="scope.row.state == 8">指定客户:
              {{ali1688CustomerName}}</span>
            <span @click="showProductBox(15)" class="per" v-if="scope.row.state == 15">指定货品:
              {{ali1688ProductCode}}</span>
            <!-- 生产bom相关 -->
            <span @click="showUserBox(11)" class="per" v-if="scope.row.state == 11">指定员工: {{bomPowerName}}</span>
            <span v-if="scope.row.state == 11 && scope.row.para_value != '未设置'" style="width: 90px;margin-left: 10px;">
              <el-checkbox :disabled="isDisabled" v-model="scope.row.is_check" @change="updateParam(scope.row,1)">
                费用相关不可设置</el-checkbox>
            </span>
            <span @click="showUserBox(12)" class="per" v-if="scope.row.state == 12">指定员工: {{bomCostPowerName}}</span>
            <span @click="showUserBox(13)" class="per" v-if="scope.row.state == 13">指定员工: {{mesCostPowerName}}</span>
            <span @click="showUserBox(17)" class="per" v-if="scope.row.state == 17">指定员工: {{costReplacementName}}</span>
        <!--    复选框显示相关
            <span v-if="scope.row.is_check_box == true" style="width: 90px;">
              <el-checkbox v-for="(item,index) in scope.row.setting" v-model="scope.row.para_value"
                :disabled="isDisabled">{{item.name}}</el-checkbox>
            </span> -->
          </template>
        </el-table-column>
        <el-table-column prop="explain" label="配置说明" min-width="100">
          <template slot-scope="scope">
            <span class="remark">{{scope.row.explain}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 负责人选择组件 -->
    <personnel-list @SelectedData="getPersonnelData" :defaultSelected="defaultSelected" v-if="show_userbox">
    </personnel-list>

    <!-- 客户选择弹框 -->
    <customerList @SelectedData="getCustomerData" v-if="show_customerbox"></customerList>

    <!-- 货品选择弹框 -->
    <productList :isMultiple="isMultiple" @SelectedData="getProductData" v-if="show_productbox"></productList>
  </div>
</template>
<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions,
  } from 'vuex'

  //导入方法
  import api from "@/network/system/controls"

  //导入组件
  import personnelList from '@/components/commComponent/list/list_personnel'; //负责人列表组件
  import customerList from '@/components/commComponent/list/list_customer'; //客户列表组件
  import productList from '@/components/commComponent/list/list_product'; //货品列表组件

  export default {
    name: 'setting_billings',
    data() {
      return {
        isDisabled: true, //是否有修改权限
        isPower: false, //是否有查询权限
        defaultSelected: [], //默认勾选
        oldData: [], //修改之前的数据

        //控制开关
        show_userbox: false, //控制加载用户组件
        show_customerbox: false, //控制加载客户组件
        show_productbox: false, //控制加载货品组件

        // 员工设置相关参数信息
        getUserState: -1, //当前获取员工的参数状态
        getCustomerState: -1, //当前获取客户的参数状态
        getProductState: -1, //当前获取货品的参数状态
        auditName: '未设置', //审核人名称
        bomExportName:'未设置',//bom导出审核人名称
        alibabaUserName: '未设置', //阿里巴巴同步未知客户指定员工
        alibabaCustomerName: '未设置', //阿里巴巴订单同步未匹配客户指定客户
        alibabaProductCode: '未设置', //阿里巴巴订单同步未匹配货品指定货品
        ali1688UserName: '未设置', //1688同步未知客户指定员工
        ali1688CustomerName: '未设置', //1688同步未匹配客户指定客户名称
        ali1688ProductCode: '未设置', //1688巴巴订单同步未匹配货品指定货品
        creditAllocationCode:'未设置',//信用配置初审人


        //货品弹框相关参数
        isMultiple: 0, //控制单选还是多选

        //生产bom相关配置
        bomPowerName: '未设置', //BOM清单/工序设置权限人
        bomCostPowerName: '未设置', //BOM/工序费用设置权限人
        mesCostPowerName: '未设置', //生产计划费用相关设置权限人
        costReplacementName:'未设置',//成本重置权限人

        // 配置数据
        auditObj: { //BOM审核人配置信息
          para_id: '285',
          para_value: '',
          type: '生产单据',
          name: 'BOM指定审核人',
        },
        // 配置数据
        bomObj: { //BOM审核人配置信息
          para_id: '375',
          para_value: '',
          type: '生产单据',
          name: 'bom导出指定人',
        },
        /* 生产配置数据 */
        data_settingProduction: [{
            type: '销售单据',
            para_id: '275',
            para_value: 0,
            name: '零库存可见',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: '当库存为0时,货品列表页是否可见'
          },
          {
            type: '销售单据',
            para_id: '276',
            para_value: 0,
            name: '成本价可见',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: '设置开启后,不管节点是否启用成本价都可见,反之都不可见'
          },
          {
            type: '销售单据',
            para_id: '277',
            para_value: 0,
            name: '零库存下单',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: '报价单不受限'
          },
          {
            type: '销售单据',
            para_id: '278',
            is_input: true,
            para_value: "",
            name: '客户授信自动结存时间',
            explain: '根据日期每个月的第几天自动结存'
          },
          {
            type: '销售单据',
            para_id: '279',
            para_value: 0,
            name: '客户授信时间结款计算方式',
            setting: [{
                id: 0,
                name: '下订单开始',
              },
              {
                id: 1,
                name: '出货单开始',
              },
              {
                id: 2,
                name: '对账单开始',
              },
            ],
            explain: ''
          },
          {
            type: '销售单据',
            para_id: '280',
            para_value: 0,
            name: '单据审核后才能打印',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: ''
          },
          {
            type: '销售单据',
            para_id: '281',
            para_value: 0,
            name: '单据审核后才能导出',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: ''
          },
          {
            type: '销售单据',
            para_id: '349',
            para_value: "",
            state: 5,
            name: 'alibaba订单同步未知客户订单转移给',
            explain: ''
          },
          {
            type: '销售单据',
            para_id: '350',
            para_value: "",
            state: 6,
            name: 'alibaba订单同步未能匹配的客户订单转移给',
            explain: ''
          },
          {
            type: '销售单据',
            para_id: '365',
            para_value: "",
            state: 14,
            name: 'alibaba货品同步未知货品转移给',
            explain: ''
          },
          {
            type: '销售单据',
            para_id: '351',
            para_value: "",
            state: 7,
            name: '1688订单同步未知客户订单转移给',
            explain: ''
          },
          {
            type: '销售单据',
            para_id: '352',
            para_value: "",
            state: 8,
            name: '1688订单同步未能匹配的客户订单转移给',
            explain: ''
          },
          {
            type: '销售单据',
            para_id: '366',
            para_value: "",
            state: 15,
            name: '1688货品同步未知货品转移给',
            explain: ''
          },
          {
            type: '出入库单',
            para_id: '282',
            para_value: 0,
            state: 3,
            is_check: true,
            name: '入库凭质检单',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: ''
          },
          {
            type: '出入库单',
            para_id: '283',
            para_value: 0,
            state: 9,
            is_check: true,
            name: '入库凭订单',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: '入库时必须使用引入源单的方式'
          },
          {
            type: '出入库单',
            para_id: '284',
            para_value: 0,
            state: 4,
            is_check: true,
            name: '出库发货凭质检单',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: ''
          }, {
            type: '出入库单',
            para_id: '357',
            para_value: 0,
            state: 10,
            is_check: true,
            name: '出库发货凭订单',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: ''
          },
          {
            type: '出入库单',
            para_id: '377',
            para_value: "",
            explain: ''
          },
          {
            type: '出入库单',
            para_id: '379',
            para_value: 0,
            state: 20,
            is_check: true,
            name: '信用时间和信用额指定初审人员',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: ''
          },
          {
            type: '出入库单',
            para_id: '328',
            para_value: 0,
            name: '单据审核后才能打印',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: ''
          }, {
            type: '出入库单',
            para_id: '329',
            para_value: 0,
            name: '单据审核后才能导出',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: ''
          }, {
            type: '出入库单',
            para_id: '346',
            para_value: 0,
            name: '出库允许库存为0',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: ''
          }, {
            type: '出入库单',
            para_id: '354',
            para_value: 0,
            name: '出库时批次不足时自动下一批次补齐',
            setting: [{
                id: 0,
                name: '手动选择',
              },
              {
                id: 1,
                name: '自动补足',
              },
            ],
            explain: ''
          }, {
            type: '采购单据',
            para_id: '299',
            para_value: 0,
            name: '单据审核后才能打印',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: ''
          },
          {
            type: '采购单据',
            para_id: '300',
            para_value: 0,
            name: '单据审核后才能导出',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: ''
          },
          {
            type: '采购单据',
            para_id: '390',
            para_value: 0,
            name: '生产是否过滤成品和半成品',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: '启用后智能排产中采购建议中会过滤相关的成品和半成品'
          },
          {
            type: '生产单据',
            para_id: '260',
            para_value: 0,
            name: '生产入库',
            setting: [{
                id: 0,
                name: '直接入库',
              },
              {
                id: 1,
                name: '引入生产单据',
              },
              {
                id: 2,
                name: '引入质检单'
              },
              {
                id: 3,
                name: '验收单+质检单'
              }
            ],
            explain: ''
          },
          {
            type: '生产单据',
            para_id: '262',
            para_value: 0,
            name: '领料方式',
            setting: [{
                id: 0,
                name: '引用生产计划单及生产执行单(可更改)',
              },
              {
                id: 1,
                name: '引用生产计划单及生产执行单(不可更改)',
              },
            ],
            explain: ''
          },
          {
            type: '生产单据',
            para_id: '264',
            para_value: 0,
            state: 2,
            name: 'BOM审核',
            setting: [{
                id: 0,
                name: '开启审核',
              },
              {
                id: 1,
                name: '不审核',
              }
            ],
            explain: 'BOM创建后，必须审核后才有效'
          },
          {
            type: '生产单据',
            para_id: '265',
            para_value: 0,
            name: '工序控制',
            setting: [{
                id: 0,
                name: '多工序同时进行',
              },
              {
                id: 1,
                name: '按流程进行',
              },
            ],
            explain: ''
          },
          {
            type: '生产单据',
            para_id: '266',
            para_value: 0,
            name: '单据审核后才能打印(生产计划单)',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: ''
          },
          {
            type: '生产单据',
            para_id: '274',
            para_value: 0,
            name: '单据审核后才能导出(生产计划单)',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: ''
          }, {
            type: '生产单据',
            para_id: '340',
            para_value: 0,
            name: '单据审核后才能打印(生产执行单)',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: ''
          },
          {
            type: '生产单据',
            para_id: '341',
            para_value: 0,
            name: '单据审核后才能导出(生产执行单)',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: ''
          },
          {
            type: '生产单据',
            para_id: '353',
            para_value: 0,
            name: '生产计划单严格按照bom清单执行',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: ''
          },
          {
            type: '生产单据',
            para_id: '359',
            para_value: "",
            is_check: true,
            state: 11,
            name: 'BOM清单/工序设置权限人',
            explain: ''
          },
          {
            type: '生产单据',
            para_id: '360',
            para_value: "",
            state: 12,
            name: 'BOM/工序费用设置权限人',
            explain: ''
          },
          {
            type: '生产单据',
            para_id: '361',
            para_value: "",
            state: 13,
            name: '生产计划费用相关设置权限人',
            explain: ''
          },
          {
            type: '生产单据',
            para_id: '367',
            para_value: 1,
            name: '领料单位按BOM单位显示',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: ''
          },
          {
            type: '生产单据',
            para_id: '373',
            para_value: 0,
            state:16,
            name: 'bom导出权限',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: '启用后只有导出权限人可以使用导出功能'
          },
          {
            type: '生产单据',
            para_id: '376',
            para_value: "",
            state: 17,
            name: '成本重置权限人',
            explain: '选择相关权限人后只有权限人才有成本重置功能'
          },
          {
            type: '生产单据',
            para_id: '380',
            para_value: 0,
            is_check: true,
            state:30,
            name: '成本核算条件配置',
            explain: ''
          },
          {
            type: '生产单据',
            para_id: '381',
            para_value: 0,
            state:31,
            is_check: false,
            name: '成本核算条件配置',
            explain: ''
          },
          {
            type: '生产单据',
            para_id: '382',
            para_value: 0,
            state:32,
            is_check: false,
            name: '成本核算条件配置',
            explain: ''
          },
          {
            type: '生产单据',
            para_id: '383',
            para_value: 0,
            state:33,
            is_check: true,
            name: '成本核算条件配置',
            explain: ''
          },
          {
            type: '生产单据',
            para_id: '384',
            para_value: 0,
            state:34,
            is_check: true,
            name: '成本核算条件配置',
            explain: ''
          },
          {
            type: '生产单据',
            para_id: '385',
            para_value: 0,
            state:35,
            is_check: true,
            name: '成本核算条件配置',
            explain: ''
          },
          {
            type: '生产单据',
            para_id: '386',
            para_value: 0,
            state:36,
            is_check: false,
            name: '成本核算条件配置',
            explain: ''
          },
          {
            type: '生产单据',
            para_id: '387',
            para_value: 0,
            state:37,
            is_check: true,
            name: '成本核算条件配置',
            explain: ''
          },
          {
            type: '生产单据',
            para_id: '388',
            para_value: 0,
            state:38,
            is_check: true,
            name: '成本核算条件配置',
            explain: ''
          },


          // {
          //   type: '单据生成',
          //   para_id: '369',
          //   para_value: [],
          //   is_check_box: true,
          //   name: '销售订单生成',
          //   setting: [{
          //       id: 0,
          //       name: '采购申请单',
          //     },
          //     {
          //       id: 1,
          //       name: '采购订单',
          //     },
          //     {
          //       id: 2,
          //       name: '生产计划单',
          //     },
          //     {
          //       id: 3,
          //       name: '销售出库单',
          //     },
          //   ],
          //   explain: ''
          // },
          // {
          //   type: '单据生成',
          //   para_id: '370',
          //   para_value: [],
          //   is_check_box: true,
          //   name: '采购订单生成',
          //   setting: [{
          //       id: 0,
          //       name: '启用',
          //     },
          //     {
          //       id: 1,
          //       name: '不启用',
          //     },
          //   ],
          //   explain: ''
          // },
        ]
      }
    },
    created() {
      this.getAllcompanyInfo() //请求企业所有参数配置
    },
    computed: {
      ...mapState({
        personnelData: state => state.hr.personnelData, //企业所有员工信息
        userPowerInfo: state => state.user.userPowerInfo, //权限
      })
    },
    methods: {
      ...mapMutations([
        'SHOW_CUSTOMERBOX', //控制客户选择弹框是否显示
        'SHOW_PERSONNELBOX', //控制显示负责人弹框
        'SHOW_PRODUCTBOX', //控制货品负责人弹框
      ]),
      ...mapMutations({
        getCompanyParamList: 'getCompanyParamList',
      }),
      ...mapActions([
        'getAllUserData', //请求企业用户数据
        'getUserPowerInfo', //获取用户操作权限
      ]),

      /* 初始化vuex数据 */
      async initVuexData() {
        //判断企业用户
        if (this.personnelData.length == 0) {
          this.getAllUserData();
        }
        //判断用户权限(需优化  这里先这样写  不知道为什么await没有用)
        if (this.userPowerInfo.length == 0) {
          await this.getUserPowerInfo();
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 1, 1, '查询')) {
            return
          }
          this.isPower = true
        } else {
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 1, 1, '查询')) {
            return
          }
          this.isPower = true
        }
      },

      /* 显示员工选择弹框 */
      showUserBox(type) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 1, 4, '修改')) {
          return
        }
        this.defaultSelected = [];
        //获取当前的操作类型
        this.getUserState = type;
        //加载用户组件
        this.show_userbox = true;
        //显示员工选择弹框
        this.SHOW_PERSONNELBOX(true);
      },

      /* 显示客户选择弹框 */
      showCustomerBox(type) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 1, 4, '修改')) {
          return
        }
        //获取当前的操作类型
        this.getCustomerState = type;
        //加载客户组件
        this.show_customerbox = true;
        //显示员工选择弹框
        this.SHOW_CUSTOMERBOX(true);
      },

      /* 显示货品选择弹框 */
      showProductBox(type) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 1, 4, '修改')) {
          return
        }
        //获取当前的操作类型
        this.getProductState = type;
        //加载客户组件
        this.show_productbox = true;
        //显示员工选择弹框
        this.SHOW_PRODUCTBOX(true);
      },

      /* 获取选择的负责人的信息 */
      getPersonnelData(data) {
        //清空默认数据
        this.defaultSelected = [];
        // 判断操作状态
        if (this.getUserState == 2) { //bom设置审核人
        // 2022115 徐总确认 bom设置审核人可以多选
          // if (data.length > 1) {
          //   this.$message({
          //     type: 'warning',
          //     message: '最多选择一个审核人',
          //     duration: this.elDuration
          //   })
          //   return false
          // }
          //判断是否为空
          if (data.length == 0) {
            //获取审核人名称
            this.auditName = "";
            //获取审核人id
            this.auditObj.para_value = "";
          } else {
            let auditAllName=[]
            let auditAllObject=[]
            data.forEach((item,index)=>{
              auditAllName.push(item.user_name)
              auditAllObject.push(item.user_id + "@@@" + item.user_name)
            })
            //获取审核人名称
            this.auditName = auditAllName.join(",");
            //获取审核人id
            this.auditObj.para_value = auditAllObject.join(",");
          }
          //修改设置后的信息
          this.updateParam(this.auditObj);
        } else if (this.getUserState == 5) { //alibaba设置默认归属用户
          if (data.length > 1) {
            this.$message({
              type: 'warning',
              message: '只能设置一个归属用户!',
              duration: this.elDuration
            })
            return false
          }
          //判断是否有选
          if (data.length == 0) {
            //获取设置的名称
            this.alibabaUserName = "";
            //获取设置的id
            this.data_settingProduction.filter(item => item.para_id == "349")[0].para_value = "";
          } else {
            //获取设置的名称
            this.alibabaUserName = data[0].user_name;
            //获取设置的id
            this.data_settingProduction.filter(item => item.para_id == "349")[0].para_value = data[0].user_id + "@@@" +
              data[0].user_name;
          }
          let curData = this.data_settingProduction.filter(item => item.para_id == "349")[0];
          this.updateParam(curData);
        } else if (this.getUserState == 7) { //1688设置默认归属用户
          if (data.length > 1) {
            this.$message({
              type: 'warning',
              message: '只能设置一个归属用户!',
              duration: this.elDuration
            })
            return false
          }
          //判断是否有选
          if (data.length == 0) {
            this.ali1688UserName = "";
            //获取设置的id
            this.data_settingProduction.filter(item => item.para_id == "351")[0].para_value = "";
          } else {
            this.ali1688UserName = data[0].user_name;
            //获取设置的id
            this.data_settingProduction.filter(item => item.para_id == "351")[0].para_value = data[0].user_id + "@@@" +
              data[0].user_name;
          }
          let curData = this.data_settingProduction.filter(item => item.para_id == "351")[0];
          this.updateParam(curData);
        } else if (this.getUserState == 11) { //BOM清单/工序设置权限人
          //判断是否有选
          if (data.length == 0) {
            this.bomPowerName = "";
            //获取设置的id
            this.data_settingProduction.filter(item => item.para_id == "359")[0].para_value = "";
          } else {
            //获取用户名称
            let userArr = []; //只含用户名称
            let userStrArr = []; //用户名称和id
            data.forEach((itemJ, indexJ) => {
              userArr.push(itemJ.user_name);
              userStrArr.push(itemJ.user_id + "@@@" + itemJ.user_name);
            })
            this.bomPowerName = userArr.join(",");
            //获取数据库存储数据
            let userStr = userStrArr.join(",");
            //获取设置的id
            this.data_settingProduction.filter(item => item.para_id == "359")[0].para_value = userStr;
          }
          let curData = this.data_settingProduction.filter(item => item.para_id == "359")[0];
          this.updateParam(curData);
        } else if (this.getUserState == 12) { //BOM/工序费用设置权限人
          //判断是否有选
          if (data.length == 0) {
            this.bomCostPowerName = "";
            //获取设置的id
            this.data_settingProduction.filter(item => item.para_id == "360")[0].para_value = "";
          } else {
            //获取用户名称
            let userArr = []; //只含用户名称
            let userStrArr = []; //用户名称和id
            data.forEach((itemJ, indexJ) => {
              userArr.push(itemJ.user_name);
              userStrArr.push(itemJ.user_id + "@@@" + itemJ.user_name);
            })
            this.bomCostPowerName = userArr.join(",");
            //获取数据库存储数据
            let userStr = userStrArr.join(",");
            //获取设置的id
            this.data_settingProduction.filter(item => item.para_id == "360")[0].para_value = userStr;
          }
          let curData = this.data_settingProduction.filter(item => item.para_id == "360")[0];
          this.updateParam(curData);
        } else if (this.getUserState == 13) { //生产计划费用相关设置权限人
          //判断是否有选
          if (data.length == 0) {
            this.mesCostPowerName = "";
            //获取设置的id
            this.data_settingProduction.filter(item => item.para_id == "361")[0].para_value = "";
          } else {
            //获取用户名称
            let userArr = []; //只含用户名称
            let userStrArr = []; //用户名称和id
            data.forEach((itemJ, indexJ) => {
              userArr.push(itemJ.user_name);
              userStrArr.push(itemJ.user_id + "@@@" + itemJ.user_name);
            })
            this.mesCostPowerName = userArr.join(",");
            //获取数据库存储数据
            let userStr = userStrArr.join(",");
            //获取设置的id
            this.data_settingProduction.filter(item => item.para_id == "361")[0].para_value = userStr;
          }
          let curData = this.data_settingProduction.filter(item => item.para_id == "361")[0];
          this.updateParam(curData);
        }
        else if (this.getUserState == 15) { //bom设置导出权限人
          //判断是否为空
          if (data.length == 0) {
            //获取审核人名称
            this.bomExportName = "";
            //获取审核人id
            this.bomObj.para_value = "";
          } else {
            let name=[]
            let id=[]
            data.forEach((item,index)=>{
              id.push(item.user_id)
              name.push(item.user_name)
            })
            //获取审核人名称
            this.bomExportName = name.join(",");
            //获取审核人id
            this.bomObj.para_value = id.join(",");
          }
          //修改设置后的信息
          this.updateParam(this.bomObj);
        }
        else if(this.getUserState == 17){//成本重置权限人
          //判断是否有选
          if (data.length == 0) {
            this.costReplacementName = "";
            //获取设置的id
            this.data_settingProduction.filter(item => item.para_id == "376")[0].para_value = "";
          } else {
            //获取用户名称
            let userArr = []; //只含用户名称
            let userStrArr = []; //用户名称和id
            data.forEach((itemJ, indexJ) => {
              userArr.push(itemJ.user_name);
              userStrArr.push(itemJ.user_id + "@@@" + itemJ.user_name);
            })
            this.costReplacementName = userArr.join(",");
            //获取数据库存储数据
            let userStr = userStrArr.join(",");
            //获取设置的id
            this.data_settingProduction.filter(item => item.para_id == "376")[0].para_value = userStr;
          }
          let curData = this.data_settingProduction.filter(item => item.para_id == "376")[0];
          this.updateParam(curData);
        }
        else if(this.getUserState==20){
          //判断是否有选
          if(data.length==0){
            this.creditAllocationCode = "";
            //获取设置的id
            this.data_settingProduction.filter(item => item.para_id == "377")[0].para_value = "";
          }else{
            //获取用户名称
              let userArr = []; //只含用户名称
              let userStrArr = []; //用户名称和id
              data.forEach((itemJ, indexJ) => {
                userArr.push(itemJ.user_name);
                userStrArr.push(itemJ.user_id + "@@@" + itemJ.user_name);
              })
              this.creditAllocationCode = userArr.join(",");
              //获取数据库存储数据
              let userStr = userStrArr.join(",");
              //获取设置的id
              this.data_settingProduction.filter(item => item.para_id == "377")[0].para_value = userStr;
            }
            let curData = this.data_settingProduction.filter(item => item.para_id == "377")[0];
            this.updateParam(curData);
          }

        //注销组件
        this.show_userbox = false;
      },

      /* 获取客户弹框选择的数据 */
      getCustomerData(data) {
        if (this.getCustomerState == 6) { //alibaba设置默认归属客户
          //判断是否有选
          if (data.length == 0) {
            this.alibabaCustomerName = "";
            //获取设置的id
            this.data_settingProduction.filter(item => item.para_id == "350")[0].para_value = "";
          } else if (data.length > 1) {
            this.$message({
              type: 'warning',
              message: '只能设置一个归属客户!',
              duration: this.elDuration
            })
            return false
          } else {
            this.alibabaCustomerName = data.customer_name;
            //获取设置的id
            this.data_settingProduction.filter(item => item.para_id == "350")[0].para_value = data.customer_id + "@@@" +
              data.customer_name;
          }
          let curData = this.data_settingProduction.filter(item => item.para_id == "350")[0];
          this.updateParam(curData);
        } else if (this.getCustomerState == 8) { //1688设置默认归属客户
          if (data.length == 0) {
            this.ali1688CustomerName = "";
            //获取设置的id
            this.data_settingProduction.filter(item => item.para_id == "352")[0].para_value = "";
          } else if (data.length > 1) {
            this.$message({
              type: 'warning',
              message: '只能设置一个归属客户!',
              duration: this.elDuration
            })
            return false
          } else {
            this.ali1688CustomerName = data.customer_name;
            //获取设置的id
            this.data_settingProduction.filter(item => item.para_id == "352")[0].para_value = data.customer_id + "@@@" +
              data.customer_name;
          }
          let curData = this.data_settingProduction.filter(item => item.para_id == "352")[0];
          this.updateParam(curData);
        }
        //注销组件
        this.show_customerbox = false;
      },

      /* 获取货品选择弹框选择的数据 */
      getProductData(data) {
        console.log(data);
        if (this.getProductState == 14) { //alibaba设置默认归属货品
          //判断是否有选
          if (data.length == 0) {
            this.alibabaProductCode = "";
            //获取设置的id
            this.data_settingProduction.filter(item => item.para_id == "365")[0].para_value = "";
          } else if (data.length > 1) {
            this.$message({
              type: 'warning',
              message: '只能设置一个归属货品!',
              duration: this.elDuration
            })
            return false
          } else {
            this.alibabaProductCode = data[0].product_code;
            //获取设置的id
            this.data_settingProduction.filter(item => item.para_id == "365")[0].para_value = data[0].id + "@@@" +
              data[0].product_code;
          }
          let curData = this.data_settingProduction.filter(item => item.para_id == "365")[0];
          this.updateParam(curData);
        } else if (this.getProductState == 15) { //1688设置默认归属货品
          if (data.length == 0) {
            this.ali1688ProductCode = "";
            //获取设置的id
            this.data_settingProduction.filter(item => item.para_id == "366")[0].para_value = "";
          } else if (data.length > 1) {
            this.$message({
              type: 'warning',
              message: '只能设置一个归属货品!',
              duration: this.elDuration
            })
            return false
          } else {
            this.ali1688ProductCode = data[0].product_code;
            //获取设置的id
            this.data_settingProduction.filter(item => item.para_id == "366")[0].para_value = data[0].id + "@@@" +
              data[0].product_code;
          }
          let curData = this.data_settingProduction.filter(item => item.para_id == "366")[0];
          this.updateParam(curData);
        }
        //注销组件
        this.show_productbox = false;
      },

      /* 请求企业所有参数配置 */
      async getAllcompanyInfo() {
        await this.initVuexData(); //初始化vuex数据
        api.getAllcompanyInfo().then(res => {
          if (res.code == 200) {
            this.data_settingProduction.forEach(itemI => {
              res.data.forEach(itemJ => {
                // if (itemJ.para_id == '285') { //BOM审核
                //   this.personnelData.forEach(itemL => {
                //     if (itemL.user_id == itemJ.para_value) {
                //       this.auditName = itemL.user_name
                //       this.auditObj.para_value = itemL.user_id
                //       this.$set(this.auditObj, 'id', itemJ.id) //id
                //       this.$set(this.auditObj, 'operate_time', itemJ.operate_time) //操作时间
                //       this.$set(this.auditObj, 'owner_id', itemJ.owner_id) //适用对象id
                //       this.$set(this.auditObj, 'owner_type', itemJ.owner_type) //适用对象类型
                //       this.$set(this.auditObj, 'old_val', itemL.user_name) //旧值
                //     }
                //   })
                // }
                if (itemJ.para_id == '285') {
                  let paraValueList=[]
                  if(!!itemJ.para_value){
                    let paralist=itemJ.para_value.split(",")
                    paralist.forEach((item,index)=>{
                      paraValueList.push(item.split("@@@")[1])
                    })
                  }
                  //获取参数值

                  let usetArr = paraValueList.join(",");
                  //判断有无值
                  if (!!usetArr) { //无值
                  this.auditName = usetArr;
                  } else {
                    this.auditName = "未设置";
                  }
                } else if (itemJ.para_id == '349') { //alibaba指定归属用户
                  let usetArr = itemJ.para_value.split("@@@");
                  //判断有无值
                  if (!usetArr[1]) { //无值
                    this.alibabaUserName = "未设置";
                  } else {
                    this.alibabaUserName = usetArr[1];
                  }
                } else if (itemJ.para_id == '350') { //alibaba指定归属客户
                  let customerArr = itemJ.para_value.split("@@@");
                  //判断有无值
                  if (!customerArr[1]) { //无值
                    this.alibabaCustomerName = "未设置";
                  } else {
                    this.alibabaCustomerName = customerArr[1];
                  }
                } else if (itemJ.para_id == '351') { //1688指定归属用户
                  let usetArr = itemJ.para_value.split("@@@");
                  //判断有无值
                  if (!usetArr[1]) { //无值
                    this.ali1688UserName = "未设置";
                  } else {
                    this.ali1688UserName = usetArr[1];
                  }
                } else if (itemJ.para_id == '352') { //1688指定归属客户
                  let customerArr = itemJ.para_value.split("@@@");
                  //判断有无值
                  if (!customerArr[1]) { //无值
                    this.ali1688CustomerName = "未设置";
                  } else {
                    this.ali1688CustomerName = customerArr[1];
                  }
                } else if (itemJ.para_id == '359') { //BOM清单/工序设置权限人
                  //判断是否有设置权限人
                  if (itemJ.para_value == "") {
                    this.bomPowerName = "未设置";
                  } else {
                    //分割多个用户
                    let userArr = itemJ.para_value.split(",");
                    //根据用户名和id
                    let userNameArr = [];
                    userArr.forEach((itemZ, indexZ) => {
                      let userItemArr = itemZ.split("@@@");
                      userNameArr.push(userItemArr[1])
                    })
                    this.bomPowerName = userNameArr.join(",");
                  }
                } else if (itemJ.para_id == '360') { //BOM/工序费用设置权限人
                  //判断是否有设置权限人
                  if (itemJ.para_value == "") {
                    this.bomCostPowerName = "未设置";
                  } else {
                    //分割多个用户
                    let userArr = itemJ.para_value.split(",");
                    //根据用户名和id
                    let userNameArr = [];
                    userArr.forEach((itemZ, indexZ) => {
                      let userItemArr = itemZ.split("@@@");
                      userNameArr.push(userItemArr[1])
                    })
                    this.bomCostPowerName = userNameArr.join(",");
                  }
                } else if (itemJ.para_id == '361') { //生产计划费用相关设置权限人
                  //判断是否有设置权限人
                  if (itemJ.para_value == "") {
                    this.mesCostPowerName = "未设置";
                  } else {
                    //分割多个用户
                    let userArr = itemJ.para_value.split(",");
                    //根据用户名和id
                    let userNameArr = [];
                    userArr.forEach((itemZ, indexZ) => {
                      let userItemArr = itemZ.split("@@@");
                      userNameArr.push(userItemArr[1])
                    })
                    this.mesCostPowerName = userNameArr.join(",");
                  }
                } else if (itemJ.para_id == '365') { //alibaba指定归属货品
                  let productArr = itemJ.para_value.split("@@@");
                  //判断有无值
                  if (!productArr[1]) { //无值
                    this.alibabaProductCode = "未设置";
                  } else {
                    this.alibabaProductCode = productArr[1];
                  }
                } else if (itemJ.para_id == '366') { //1688指定归属货品
                  let productArr = itemJ.para_value.split("@@@");
                  //判断有无值
                  if (!productArr[1]) { //无值
                    this.ali1688ProductCode = "未设置";
                  } else {
                    this.ali1688ProductCode = productArr[1];
                  }
                }
                else if (itemJ.para_id == '375') {
                  //判断有无值
                  if (!itemJ.para_value) { //无值
                    this.bomExportName = "未设置";
                  } else {
                    let bomName=[]
                    let paraValue=itemJ.para_value.split(",");
                    //获取用户
                    this.personnelData.forEach(itemL => {
                      if (paraValue.includes(itemL.user_id.toString())) {
                        bomName.push(itemL.user_name)
                      }
                    })
                    this.bomExportName = bomName.join(",");
                  }
                }
                else if (itemJ.para_id == '376') { //成本重置权限人
                  //判断是否有设置权限人
                  if (itemJ.para_value == "") {
                    this.costReplacementName = "未设置";
                  } else {
                    //分割多个用户
                    let userArr = itemJ.para_value.split(",");
                    //根据用户名和id
                    let userNameArr = [];
                    userArr.forEach((itemZ, indexZ) => {
                      let userItemArr = itemZ.split("@@@");
                      userNameArr.push(userItemArr[1])
                    })
                    this.costReplacementName = userNameArr.join(",");
                  }
                }
                else if(itemJ.para_id=='377'){//信用时间配置
                //判断是否有设置权限人
                if (itemJ.para_value == "") {
                  this.creditAllocationCode = "未设置";
                } else {
                  //分割多个用户
                  let userArr = itemJ.para_value.split(",");
                  //根据用户名和id
                  let userNameArr = [];
                  userArr.forEach((itemZ, indexZ) => {
                    let userItemArr = itemZ.split("@@@");
                    userNameArr.push(userItemArr[1])
                  })
                  this.creditAllocationCode = userNameArr.join(",");
                }
                }
                //设置界面数据源数据
                if (itemI.para_id == itemJ.para_id) {
                  //公共参数
                  this.$set(itemI, 'id', itemJ.id) //id
                  this.$set(itemI, 'operate_time', itemJ.operate_time) //操作时间
                  this.$set(itemI, 'owner_id', itemJ.owner_id) //适用对象id
                  this.$set(itemI, 'owner_type', itemJ.owner_type) //适用对象类型
                  //判断不同类型特殊处理
                  if (itemI.para_id == '278') { //授信自动结存时间
                    itemI.para_value = itemJ.para_value
                    this.$set(itemI, 'old_val', itemJ.para_value) //旧值
                  } else if (itemI.para_id == '282') { //入库凭质检单
                    itemI.para_value = Number(itemJ.para_value)
                    this.$set(itemI, 'old_val', Number(itemJ.para_value)) //旧值
                    let isItem = res.data.filter(itemK => itemK.para_id == '342') //仅指定采购入库单
                    itemI.is_check = false
                    if (isItem[0].para_value == 0) { //para_value为0时代表仅指定采购入库单
                      itemI.is_check = true
                    }
                    this.$set(itemI, 'limit', isItem[0]) //储存限制单据
                  } else if (itemI.para_id == '283') { //入库凭订单
                    itemI.para_value = Number(itemJ.para_value)
                    let isItem = res.data.filter(itemK => itemK.para_id == '344') //仅指定采购入库单
                    itemI.is_check = false
                    if (isItem[0].para_value == 0) { //para_value为0时代表仅指定采购入库单
                      itemI.is_check = true
                    }
                    this.$set(itemI, 'limit', isItem[0]) //储存限制单据
                    this.$set(itemI, 'old_val', Number(itemJ.para_value)) //旧值
                  } else if (itemI.para_id == '284') { //出库发货凭质检单
                    itemI.para_value = Number(itemJ.para_value)
                    let isItem = res.data.filter(itemK => itemK.para_id == '343') //仅指定销售出库单
                    itemI.is_check = false
                    if (isItem[0].para_value == 0) { //para_value为0时代表仅指定销售出库单
                      itemI.is_check = true
                    }
                    this.$set(itemI, 'limit', isItem[0]) //储存限制单据
                    this.$set(itemI, 'old_val', Number(itemJ.para_value)) //旧值
                  } else if (itemI.para_id == '357') { //出库发货凭订单
                    itemI.para_value = Number(itemJ.para_value)
                    let isItem = res.data.filter(itemK => itemK.para_id == '358') //仅指定销售出库单
                    itemI.is_check = false
                    if (isItem[0].para_value == 0) { //para_value为0时代表仅指定销售出库单
                      itemI.is_check = true
                    }
                    this.$set(itemI, 'limit', isItem[0]) //储存限制单据
                    this.$set(itemI, 'old_val', Number(itemJ.para_value)) //旧值
                  } else if (itemI.para_id == '379') { //信用时间配置
                    itemI.para_value = Number(itemJ.para_value)
                    let isItem = res.data.filter(itemK => itemK.para_id == '378') //所有销售出库单
                    itemI.is_check = false
                    if (isItem[0].para_value == 0) { //para_value为0时代表所有销售出库单
                      itemI.is_check = true
                    }
                    this.$set(itemI, 'limit', isItem[0]) //储存限制单据
                    this.$set(itemI, 'old_val', Number(itemJ.para_value)) //旧值
                  }

                  else if (itemI.para_id == '380') { //物料费用
                    itemI.para_value = Number(itemJ.para_value)
                    let isItem = res.data.filter(itemK => itemK.para_id == '380') //物料费用
                    itemI.is_check = false
                    if (isItem[0].para_value == 0) { //para_value为0时物料费用
                      itemI.is_check = true
                    }
                    this.$set(itemI, 'limit', isItem[0]) //储存限制单据
                    this.$set(itemI, 'old_val', Number(itemJ.para_value)) //旧值
                  }
                  else if (itemI.para_id == '381') { //预计物料费用
                    itemI.para_value = Number(itemJ.para_value)
                    let isItem = res.data.filter(itemK => itemK.para_id == '381') //预计物料费用
                    itemI.is_check = false
                    if (isItem[0].para_value == 0) { //para_value为0时预计物料费用
                      itemI.is_check = true
                    }
                    this.$set(itemI, 'limit', isItem[0]) //储存限制单据
                    this.$set(itemI, 'old_val', Number(itemJ.para_value)) //旧值
                  }
                  else if (itemI.para_id == '382') { //物料加工
                    itemI.para_value = Number(itemJ.para_value)
                    let isItem = res.data.filter(itemK => itemK.para_id == '382') //物料加工
                    itemI.is_check = false
                    if (isItem[0].para_value == 0) { //para_value为0时物料加工
                      itemI.is_check = true
                    }
                    this.$set(itemI, 'limit', isItem[0]) //储存限制单据
                    this.$set(itemI, 'old_val', Number(itemJ.para_value)) //旧值
                  }
                  else if (itemI.para_id == '383') { //人工费
                    itemI.para_value = Number(itemJ.para_value)
                    let isItem = res.data.filter(itemK => itemK.para_id == '383') //人工费
                    itemI.is_check = false
                    if (isItem[0].para_value == 0) { //para_value为0时人工费
                      itemI.is_check = true
                    }
                    this.$set(itemI, 'limit', isItem[0]) //储存限制单据
                    this.$set(itemI, 'old_val', Number(itemJ.para_value)) //旧值
                  }
                  else if (itemI.para_id == '384') { //管理费
                    itemI.para_value = Number(itemJ.para_value)
                    let isItem = res.data.filter(itemK => itemK.para_id == '384') //管理费
                    itemI.is_check = false
                    if (isItem[0].para_value == 0) { //para_value为0时管理费
                      itemI.is_check = true
                    }
                    this.$set(itemI, 'limit', isItem[0]) //储存限制单据
                    this.$set(itemI, 'old_val', Number(itemJ.para_value)) //旧值
                  }
                  else if (itemI.para_id == '385') { //制造费
                    itemI.para_value = Number(itemJ.para_value)
                    let isItem = res.data.filter(itemK => itemK.para_id == '385') //制造费
                    itemI.is_check = false
                    if (isItem[0].para_value == 0) { //para_value为0时制造费
                      itemI.is_check = true
                    }
                    this.$set(itemI, 'limit', isItem[0]) //储存限制单据
                    this.$set(itemI, 'old_val', Number(itemJ.para_value)) //旧值
                  }
                  else if (itemI.para_id == '386') { //其它费用
                    itemI.para_value = Number(itemJ.para_value)
                    let isItem = res.data.filter(itemK => itemK.para_id == '386') //其它费用
                    itemI.is_check = false
                    if (isItem[0].para_value == 0) { //para_value为0时其它费用
                      itemI.is_check = true
                    }
                    this.$set(itemI, 'limit', isItem[0]) //储存限制单据
                    this.$set(itemI, 'old_val', Number(itemJ.para_value)) //旧值
                  }
                  else if (itemI.para_id == '387') { //报销费用
                    itemI.para_value = Number(itemJ.para_value)
                    let isItem = res.data.filter(itemK => itemK.para_id == '387') //报销费用
                    itemI.is_check = false
                    if (isItem[0].para_value == 0) { //para_value为0时报销费用
                      itemI.is_check = true
                    }
                    this.$set(itemI, 'limit', isItem[0]) //储存限制单据
                    this.$set(itemI, 'old_val', Number(itemJ.para_value)) //旧值
                  }
                  else if (itemI.para_id == '388') { //报废数也计成本
                    itemI.para_value = Number(itemJ.para_value)
                    let isItem = res.data.filter(itemK => itemK.para_id == '388') //报废数也计成本
                    itemI.is_check = false
                    if (isItem[0].para_value == 0) { //para_value为0时报废数也计成本
                      itemI.is_check = true
                    }
                    this.$set(itemI, 'limit', isItem[0]) //储存限制单据
                    this.$set(itemI, 'old_val', Number(itemJ.para_value)) //旧值
                  }
                  else if (itemI.para_id == '349') { //alibaba订单同步未知客户订单转移给
                    let paramArr = itemJ.para_value.split("@@@");
                    //判断有无值
                    if (!paramArr[1]) { //无值
                      this.$set(itemI, 'old_val', "未设置"); //旧值
                    } else {
                      this.$set(itemI, 'old_val', paramArr[1]); //旧值
                    }
                  } else if (itemI.para_id == '350') { //alibaba订单同步未能匹配的客户订单转移给
                    let paramArr = itemJ.para_value.split("@@@");
                    //判断有无值
                    if (!paramArr[1]) { //无值
                      this.$set(itemI, 'old_val', "未设置"); //旧值
                    } else {
                      this.$set(itemI, 'old_val', paramArr[1]); //旧值
                    }
                  } else if (itemI.para_id == '351') { //1688订单同步未知客户订单转移给
                    let paramArr = itemJ.para_value.split("@@@");
                    //判断有无值
                    if (!paramArr[1]) { //无值
                      this.$set(itemI, 'old_val', "未设置"); //旧值
                    } else {
                      this.$set(itemI, 'old_val', paramArr[1]); //旧值
                    }
                  } else if (itemI.para_id == '352') { //1688订单同步未能匹配的客户订单转移给
                    let paramArr = itemJ.para_value.split("@@@");
                    //判断有无值
                    if (!paramArr[1]) { //无值
                      this.$set(itemI, 'old_val', "未设置"); //旧值
                    } else {
                      this.$set(itemI, 'old_val', paramArr[1]); //旧值
                    }
                  } else if (itemI.para_id == '359') { //BOM清单/工序设置权限人
                    let paramArr = itemJ.para_value.split("@@@");
                    //判断有无值
                    if (!paramArr[1]) { //无值
                      this.$set(itemI, 'old_val', "未设置"); //旧值
                    } else {
                      this.$set(itemI, 'old_val', paramArr[1]); //旧值
                    }
                    //处理子项
                    let isItem = res.data.filter(itemK => itemK.para_id == '362') //费用相关不可设置
                    itemI.is_check = false
                    if (isItem[0].para_value == 0) { //para_value为1时代表 费用相关不可设置
                      itemI.is_check = true
                    }
                    this.$set(itemI, 'limit', isItem[0]) //储存限制单据
                    this.$set(itemI, 'old_val', Number(itemJ.para_value)) //旧值
                  } else if (itemI.para_id == '360') { //BOM/工序费用设置权限人
                    let paramArr = itemJ.para_value.split("@@@");
                    //判断有无值
                    if (!paramArr[1]) { //无值
                      this.$set(itemI, 'old_val', "未设置"); //旧值
                    } else {
                      this.$set(itemI, 'old_val', paramArr[1]); //旧值
                    }
                  } else if (itemI.para_id == '361') { //生产计划费用相关设置权限人
                    let paramArr = itemJ.para_value.split("@@@");
                    //判断有无值
                    if (!paramArr[1]) { //无值
                      this.$set(itemI, 'old_val', "未设置"); //旧值
                    } else {
                      this.$set(itemI, 'old_val', paramArr[1]); //旧值
                    }
                  }
                  else if (itemI.para_id == '376') { //成本重置权限人
                    let paramArr = itemJ.para_value.split("@@@");
                    //判断有无值
                    if (!paramArr[1]) { //无值
                      this.$set(itemI, 'old_val', "未设置"); //旧值
                    } else {
                      this.$set(itemI, 'old_val', paramArr[1]); //旧值
                    }
                  }
                   else { //其他配置项
                    itemI.para_value = Number(itemJ.para_value)
                    this.$set(itemI, 'old_val', Number(itemJ.para_value)) //旧值
                  }
                }

                //特殊处理的数据
                if (itemJ.para_id == '285') { //bom审核人
                  this.$set(this.auditObj, 'id', itemJ.id) //id
                  this.$set(this.auditObj, 'operate_time', itemJ.operate_time) //操作时间
                  this.$set(this.auditObj, 'owner_id', itemJ.owner_id) //适用对象id
                  this.$set(this.auditObj, 'owner_type', itemJ.owner_type) //适用对象类型
                  let paraValueList=[]
                  if(!!itemJ.para_value){
                    let paralist=itemJ.para_value.split(",")
                    paralist.forEach((item,index)=>{
                      paraValueList.push(item.split("@@@")[1])
                    })
                  }
                  //获取参数值
                  let paramArr = paraValueList.join(",");
                  //判断有无值
                  if (!!paramArr) { //无值
                    this.$set(this.auditObj, 'old_val', paramArr); //旧值
                  } else {
                    this.$set(this.auditObj, 'old_val', "未设置"); //旧值
                  }
                }
                if(itemJ.para_id == '375'){//bom导出审核人
                  this.$set(this.bomObj, 'id', itemJ.id) //id
                  this.$set(this.bomObj, 'operate_time', itemJ.operate_time) //操作时间
                  this.$set(this.bomObj, 'owner_id', itemJ.owner_id) //适用对象id
                  this.$set(this.bomObj, 'owner_type', itemJ.owner_type) //适用对象类型
                  //获取参数值
                  let paramArr = itemJ.para_value.split("@@@");
                  //判断有无值
                  if (!paramArr[1]) { //无值
                    this.$set(this.bomObj, 'old_val', "未设置"); //旧值
                  } else {
                    this.$set(this.bomObj, 'old_val', paramArr[1]); //旧值
                  }
                }
              })
            })
            //判断是否有修改权限
            if (this.commonJsExtend.isHaveThePower(22, 1, 4, '修改', 0)) {
              return
            }
            this.isDisabled = false;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 修改参数 type=>1 仅采购入库单 仅销售出库单 (特殊处理)*/
      updateParam(data, type) {
        let logContent = '';
        if (data.para_id == '285') { //审核人
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${data.old_val},修改后:${this.auditName}` //拼接日志
        } else if (data.para_id == '278') { //自动结存时间
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${data.old_val},修改后:${data.para_value}` //拼接日志
        } else if (data.para_id == '349') {
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${data.old_val},修改后:${data.para_value}` //拼接日志
        } else if (data.para_id == '350') {
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${data.old_val},修改后:${data.para_value}` //拼接日志
        } else if (data.para_id == '351') {
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${data.old_val},修改后:${data.para_value}` //拼接日志
        } else if (data.para_id == '352') {
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${data.old_val},修改后:${data.para_value}` //拼接日志
        } else if (data.para_id == '359') {
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${data.old_val},修改后:${data.para_value}` //拼接日志
        } else if (data.para_id == '360') {
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${data.old_val},修改后:${data.para_value}` //拼接日志
        } else if (data.para_id == '361') {
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${data.old_val},修改后:${data.para_value}` //拼接日志
        } else if (data.para_id == '365') {
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${data.old_val},修改后:${data.para_value}` //拼接日志
        } else if (data.para_id == '366') {
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${data.old_val},修改后:${data.para_value}` //拼接日志
        }else if (data.para_id == '375') {
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${data.old_val},修改后:${data.para_value}` //拼接日志
        }
        else if (data.para_id == '376') {
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${data.old_val},修改后:${data.para_value}` //拼接日志
        }
        else if (data.para_id == '377') {
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${data.old_val},修改后:${data.para_value}` //拼接日志
        }
        else if (data.para_id == '380') {
          if(data.is_check){
            let i=0;
            this.data_settingProduction.forEach((item,index)=>{
              if(item.para_id=='381' && item.is_check){
                i=1
              }
            })
            if(i==1){
              this.$message({
                type:"warning",
                message:"请不要同时勾选物料费用和预计物料费用",
              })
              this.getAllcompanyInfo(); //获取企业信息
              this.getCompanyParamList(); //刷新数据
              return
            }
          }
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${data.old_val},修改后:${data.para_value}` //拼接日志
        }
        else if (data.para_id == '381') {
          if(data.is_check){
            let i=0;
            this.data_settingProduction.forEach((item,index)=>{
              if(item.para_id=='380' && item.is_check){
                i=1
              }
            })
            if(i==1){
              this.$message({
                type:"warning",
                message:"请不要同时勾选物料费用和预计物料费用",
              })
              this.getAllcompanyInfo(); //获取企业信息
              this.getCompanyParamList(); //刷新数据
              return
            }
          }
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${data.old_val},修改后:${data.para_value}` //拼接日志
        }
        else if (data.para_id == '382') {
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${data.old_val},修改后:${data.para_value}` //拼接日志
        }
        else if (data.para_id == '383') {
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${data.old_val},修改后:${data.para_value}` //拼接日志
        }
        else if (data.para_id == '384') {
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${data.old_val},修改后:${data.para_value}` //拼接日志
        }
        else if (data.para_id == '385') {
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${data.old_val},修改后:${data.para_value}` //拼接日志
        }
        else if (data.para_id == '386') {
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${data.old_val},修改后:${data.para_value}` //拼接日志
        }
        else if (data.para_id == '387') {
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${data.old_val},修改后:${data.para_value}` //拼接日志
        }
        else if (data.para_id == '388') {
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${data.old_val},修改后:${data.para_value}` //拼接日志
        }
         else { //其他
          let oldVal = '',
            newVal = ''
          data.setting.forEach(item => {
            if (item.id == data.old_val) {
              oldVal = item.name //获取参数值所对应的名称
            }
            if (item.id == data.para_value) {
              newVal = item.name //获取参数值所对应的名称
            }
          })
          logContent = `修改了【${data.type}】中的【${data.name}】,修改前:${oldVal},修改后:${newVal}` //拼接日志
        }
        if (type == 1) {
          let limitData = {
            id: data.limit.id,
            operate_time: data.limit.operate_time,
            owner_id: data.limit.owner_id,
            owner_type: data.limit.owner_type,
            para_id: data.limit.para_id,
            para_value: data.is_check ? 0 : 1,
          }
          data = limitData
        }
        data.logContent = logContent;
        api.updateSysParametersValue(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '修改参数配置成功',
              duration: this.elDuration
            })
            this.getAllcompanyInfo(); //获取企业信息
            this.getCompanyParamList(); //刷新数据
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 合并行 */
      getJBXXTableData() {
        let spanOneArr = [],
          spanTwoArr = [],
          concatOne = 0,
          concatTwo = 0;
        this.data_settingProduction.forEach((item, index) => {
          if (index === 0) {
            spanOneArr.push(1);
            spanTwoArr.push(1);
          } else {
            if (item.type === this.data_settingProduction[index - 1].type) { //第一列需合并相同内容的判断条件
              spanOneArr[concatOne] += 1;
              spanOneArr.push(0);
            } else {
              spanOneArr.push(1);
              concatOne = index;
            };
            if (item.name === this.data_settingProduction[index - 1].name) { //第二列需合并相同内容的判断条件
              spanTwoArr[concatTwo] += 1;
              spanTwoArr.push(0);
            } else {
              spanTwoArr.push(1);
              concatTwo = index;
            };
          }
        });
        return {
          one: spanOneArr,
          two: spanTwoArr
        }
      },

      // 前行row、当前列column、当前行号rowIndex、当前列号columnIndex
      objectJMethod({
        row,
        column,
        rowIndex,
        columnIndex
      }) {
        if (columnIndex === 0) {
          const _row = (this.getJBXXTableData(this.data_settingProduction).one)[rowIndex];
          const _col = _row > 0 ? 1 : 0;
          return {
            rowspan: _row,
            colspan: _col
          };
        }
        if (columnIndex === 1) {
          const _row = (this.getJBXXTableData(this.data_settingProduction).two)[rowIndex];
          const _col = _row > 0 ? 1 : 0;
          return {
            rowspan: _row,
            colspan: _col
          };
        }
      },
    },
    components: {
      personnelList,
      customerList,
      productList
    },
  }
</script>

<style>
  .button {
    margin-bottom: 5px;
  }

  .per {
    color: #0099FF;
    font-size: 14px;
    cursor: pointer;
  }

  .remark {
    color: red;
  }
</style>
