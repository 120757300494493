<template>
  <div class="systemItemIndex">
    <el-tabs tab-position="left" v-model="activeName" style="height: 100%;" @tab-click="tabChange">
      <!-- <el-tab-pane label="客户配置" name="0">
        <customerSetting v-if="activeName == 0"></customerSetting>
      </el-tab-pane> -->
      <el-tab-pane label="单据配置" name="1">
        <productionSetting v-if="activeName == 1"></productionSetting>
      </el-tab-pane>
      <!-- <el-tab-pane label="生产配置" name="5">
        <mesSetting v-if="activeName == 5"></mesSetting>
      </el-tab-pane> -->
      <el-tab-pane label="货品配置" name="2">
        <productSetting v-if="activeName == 2"></productSetting>
      </el-tab-pane>
      <el-tab-pane label="财务配置" name="3">
        <financeSetting v-if="activeName == 3"></financeSetting>
      </el-tab-pane>
      <el-tab-pane label="上传配置" name="4">
        <uploadSetting v-if="activeName == 4"></uploadSetting>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  // 导入组件
  import customerSetting from './setting_customer.vue' //客户配置
  import productionSetting from './setting_billings.vue' //单据配置
  import productSetting from './setting_product.vue' //货品配置
  import financeSetting from './setting_finance.vue' //财务配置
  import uploadSetting from './upload_setting.vue' //上传配置
  import mesSetting from './setting_mes.vue';//生产配置

  export default {
    name: '',
    data() {
      return {
        activeName: '1',
        ProductionOldVal: '', //单据配置
        ProductOldVal: '', //货品配置
        UploadOldVal: '', //财务配置
        FinanceOldVal: '' //货品配置
      }
    },

    watch: {
      $route() {
        this.tabChange({
          name: this.$route.query.id
        })
      }
    },
    created() {
      this.activeName = this.$route.query.id
      this.tabChange({
        name: this.activeName
      })
    },

    /* 路由守卫判断当前页面的参数值是否改变 */
    // beforeRouteLeave(to, from, next) {
    //   let newVal = this.getNewVal() //获取编辑后数据
    //   let oldVal = this.getOldVal() //获取编辑前数据
    //   if (newVal !== oldVal) { // 当页面值修改时执行路由守卫
    //     this.$confirm('当前信息未保存，离开页面将会放弃所有修改数据', '提示', {
    //       closeOnClickModal: false,
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       type: 'warning'
    //     }).then(() => {
    //       // 点击确定则往下执行
    //       next()
    //     }).catch(() => {
    //       // 取消则关闭弹窗不执行
    //     })
    //   } else {
    //     // 条件不成立则继续往下执行
    //     next()
    //   }
    // },

    methods: {
      /* 改变路由 */
      tabChange(tab) {
        this.$router.push({
          path: this.$route.path,
          query: {
            id: tab.name
          }
        })
      },

      /* tab切换前 */
      // beforeLeave() {
      //   let newVal = this.getNewVal() //获取编辑后数据
      //   let oldVal = this.getOldVal() //获取编辑前数据
      //   let tab = new Promise((resolve, reject) => {
      //     if (newVal !== oldVal) { // 当页面值修改时
      //       this.$confirm('当前信息未保存，离开页面将会放弃所有修改数据', '提示', {
      //         closeOnClickModal: false,
      //         confirmButtonText: '确定',
      //         cancelButtonText: '取消',
      //         type: 'warning'
      //       }).then(() => {
      //         resolve()
      //       }).catch(err => {
      //         reject(err)
      //       })
      //     } else {
      //       resolve()
      //     }
      //   })
      //   return tab
      // },

      /* 获取单据配置旧数据 */
      getProductionOldVal(val) {
        this.ProductionOldVal = val
      },

      /* 获取货品配置旧数据 */
      getProductOldVal(val) {
        this.ProductOldVal = val
      },

      /* 获取财务配置旧数据 */
      getFinanceOldVal(val) {
        this.FinanceOldVal = val
      },

      /* 获取货品配置旧数据 */
      getUploadOldVal(val) {
        this.UploadOldVal = val
      },

      /* 获取当前页面旧数据 */
      getOldVal() {
        let oldVal = ''
        switch (this.activeName) {
          case '1':
            oldVal = this.ProductionOldVal.join(",")
            break;
          case '2':
            oldVal = this.ProductOldVal.substring(0, this.ProductOldVal.length - 2)
            break;
          case '3':
            oldVal = this.FinanceOldVal.toString()
            break;
          case '4':
            oldVal = this.UploadOldVal.toString()
            break;
        }
        return oldVal
      },

      /* 获取当前页面新数据 */
      getNewVal() {
        let newVal = ''
        switch (this.activeName) {
          case '1':
            newVal = this.$refs.productionSetting.getNewValue().newData.join(",")
            break;
          case '2':
            newVal = this.$refs.productSetting.getNewValue().newValue.slice(2);
            break;
          case '3':
            newVal = this.$refs.financeSetting.getNewValue();
            break;
          case '4':
            newVal = this.$refs.uploadSetting.getNewValue();
            break;
        }
        return newVal
      }

    },
    components: {
      customerSetting,
      productionSetting,
      productSetting,
      financeSetting,
      uploadSetting,
      mesSetting
    }
  }
</script>
