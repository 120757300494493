<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：财务配置
开始时间：2021-08-25
开发人员：康泽鑫
最后修改：2021-08-25
备注说明：如需修改请联系开发人员
-->
<template>
  <div class="setting_itemBox" id="upload_setting_Box">
    <!-- 配置内容 -->
    <div class="setting_contentBox billing_table" v-if="isPower">
      <el-table id="classifyTable" :data="financeSettingData" height="100%" border style="width: 100%">
        <el-table-column prop="name" sortable label="参数名称" width="400"></el-table-column>
        <el-table-column prop="setting" label="参数设置" width="500">
          <template slot-scope="scope">
            <!-- 输入框样式 -->
            <span v-if="scope.row.isInput">
              <el-input v-model="scope.row.para_value" placeholder="请输入文件大小" size="mini" :disabled="isDisabled"
                onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>{{scope.row.unit}}
            </span>
            <!-- 单选按钮样式 -->
            <el-radio-group v-if="scope.row.isRadio" v-model="scope.row.para_value" @change="updateParam(scope.row)"
              :disabled="isDisabled">
              <el-radio label="0">{{scope.row.option[0]}}</el-radio>
              <el-radio label="1">{{scope.row.option[1]}}</el-radio>
            </el-radio-group>
            <!-- 计数器样式 -->
            <div v-if="scope.row.isInputNum">
              每月
              <el-input-number v-model="scope.row.para_value" controls-position="right" @change="updateParam(scope.row)"
                :disabled="isDisabled" size="mini" :min="0" :max="31"></el-input-number>
              号
            </div>
          </template>
        </el-table-column>
        <el-table-column label="配置说明">
          <template slot-scope="scope">
            <span class="remark">{{scope.row.remark}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  //导入方法
  import api from "@/network/system/controls"
  export default {
    name: 'setting_finance',
    data() {
      return {
        isDisabled: true, //是否有修改权限
        isPower: false, //是否有查询权限
        loading: '', //loading框
        oldValue: [], //编辑前数据
        financeSettingData: [{
          name: '采购单价自动更新到BOM清单', //参数名称
          isInput: false, //是否是输入框
          isRadio: true, //是否单选钮
          isInputNum: false, //是否为计数器
          para_value: '0', //参数设置
          unit: '', //参数单位
          option: ['更新(入库单引入后审核后)', '不更新'], //选项值
          remark: '更新后已设bom所有物料信息都更新', //配置说明
        }, {
          name: ' 收付款是否凭单据', //参数名称
          isInput: false, //是否是输入框
          isRadio: true, //是否单选钮
          isInputNum: false, //是否为计数器
          para_value: '0', //参数设置
          unit: '', //参数单位
          option: ['是', '否'], //选项值
          remark: '', //配置说明
        }, {
          name: '采购开票模式', //参数名称
          isInput: false, //是否是输入框
          isRadio: true, //是否单选钮
          isInputNum: false, //是否为计数器
          para_value: '0', //参数设置
          unit: '', //参数单位
          option: ['入库后产生待开发票', '订单下达后就可以开票'], //选项值
          remark: '', //配置说明
        }, {
          name: '销售开票模式', //参数名称
          isInput: false, //是否是输入框
          isRadio: true, //是否单选钮
          isInputNum: false, //是否为计数器
          para_value: '0', //参数设置
          unit: '', //参数单位
          option: ['出库后产生待开发票', '订单下达后就可以开票'], //选项值
          remark: '', //配置说明
        }, {
          name: '采购销售收付款方式', //参数名称
          isInput: false, //是否是输入框
          isRadio: true, //是否单选钮
          isInputNum: false, //是否为计数器
          para_value: '0', //参数设置
          unit: '', //参数单位
          option: ['一次付款一张订单', '一次可多张订单'], //选项值
          remark: '', //配置说明
        }, {
          name: '客户对账单结存时间', //参数名称
          isInput: false, //是否是输入框
          isRadio: false, //是否单选钮
          isInputNum: true, //是否为计数器
          para_value: '0', //参数设置
          unit: '', //参数单位
          option: [], //选项值
          remark: '', //配置说明
        }, {
          name: '供应商对账单结存时间', //参数名称
          isInput: false, //是否是输入框
          isRadio: false, //是否单选钮
          isInputNum: true, //是否为计数器
          para_value: '0', //参数设置
          unit: '', //参数单位
          option: [], //选项值
          remark: '', //配置说明
        }]
      }
    },
    computed: {
      ...mapState({
        userPowerInfo: state => state.user.userPowerInfo, //权限
      })
    },
    created() {
      this.getUploadSetting()
    },
    methods: {
      ...mapMutations({
        getCompanyParamList: 'getCompanyParamList',
      }),
      ...mapActions([
        'getUserPowerInfo', //获取用户操作权限
      ]),
      /* 获取财务配置信息 */
      async getUploadSetting() {
        //判断用户权限(需优化  这里先这样写  不知道为什么await没有用)
        if (this.userPowerInfo.length == 0) {
          await this.getUserPowerInfo();
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 3, 1, '查询')) {
            return
          }
          this.isPower = true
        } else {
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 3, 1, '查询')) {
            return
          }
          this.isPower = true
        }
        this.oldValue = [] //清空编辑前数据
        //发送请求
        api.getAllcompanyInfo().then(res => {
          if (res.code == 200) {
            res.data.forEach(itemI => { //循环返回结果
              let paraIds = ['323', '324', '325', '326', '327', '363', '364'] //参数id数组
              paraIds.forEach((itemJ, indexJ) => { //循环参数id数组
                if (itemI.para_id == itemJ) { //匹配对应对象
                  this.financeSettingData[indexJ].para_value = itemI.para_value //参数值
                  this.$set(this.financeSettingData[indexJ], 'id', itemI.id) //参数id
                  this.$set(this.financeSettingData[indexJ], 'para_id', itemI.para_id) //参数id
                  this.$set(this.financeSettingData[indexJ], 'operate_time', itemI.operate_time) //操作时间
                  this.$set(this.financeSettingData[indexJ], 'owner_id', itemI.owner_id) //适用对象id
                  this.$set(this.financeSettingData[indexJ], 'owner_type', itemI.owner_type) //适用对象类型
                  this.$set(this.financeSettingData[indexJ], 'old_val', itemI.para_value) //旧值
                  this.oldValue.push(itemI.para_value)
                }
              })
            })
            //判断是否有修改权限
            if (this.commonJsExtend.isHaveThePower(22, 3, 4, '修改', 0)) {
              return
            }
            this.isDisabled = false
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取修改后数据 */
      getNewValue() {
        let newValue = [] //编辑后参数
        this.financeSettingData.forEach(item => {
          newValue.push(item.para_value)
        })
        return newValue.toString()
      },

      /* 修改参数*/
      updateParam(data) {
        data.logContent =
          `修改了【财务配置】中的【${data.name}】,修改前:${data.option[data.old_val]},修改后:${data.option[data.para_value]}` //日志
        api.updateSysParametersValue(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '修改参数配置成功'
            })
            this.getUploadSetting()
            this.getCompanyParamList() //刷新vuex
          } else {
            this.$message({
              type: 'error',
              message: res.message
            })
          }
        })
      },

      /* 修改财务配置信息 */
      updateData() {
        let oldvalue = this.oldValue.toString() + '###' + this.getNewValue() //日志拼接
        //准备后端参数
        var data = {
          oldvalue,
          sSysParamatersValue: JSON.stringify(this.financeSettingData),
        }
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#upload_setting_Box", 4, '数据加载中,请稍后...');
        api.updateFinanceSysParame(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '参数修改成功!',
              duration: this.elDuration
            })
            this.getUploadSetting() //刷新数据
            this.getCompanyParamList() //刷新vuex
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      }
    }
  }
</script>

<style>
  .button {
    margin-bottom: 5px;
  }

  .remark {
    color: red;
    white-space: pre-line
  }
</style>
