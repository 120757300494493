<template>
  <div id="customer">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基本配置" name="first">
        <el-button type="primary" size="small" class="button" @click="updateData">保存更改</el-button>
          <div class="billing_table">
<!--            <table class="system_table">
              <thead>
                <tr>
                  <th width="300px">参数名称</th>
                  <th width="100px">是否启用该功能</th>
                  <th width="100px">限制数</th>
                  <th width="100px">非限制员工</th>
                  <th>配置说明</th>
                </tr>
              </thead>
            </table> -->
            <el-table id="classifyTable" :data="baseInfo" height="100%" border style="width: 100%">
               <el-table-column prop="name" sortable label="参数名称" width="300"></el-table-column>
               <el-table-column prop="name" label="是否启用该功能" width="300">
                 <template slot-scope="scope">
                    <el-checkbox v-if="scope.row.enabel == true" v-model="scope.row.is_enable"></el-checkbox>
                 </template>
               </el-table-column>
               <el-table-column prop="name" label="限制数" width="300">
                 <template slot-scope="scope">
                    <el-input maxlength="6" oninput="this.value=this.value.replace(/[^0-9]/g,'')" :disabled="scope.row.is_enable == false" v-if="scope.row.type != true && scope.row.input != true" size="mini" placeholder="请输入限制数" v-model="scope.row.count"></el-input>
                    <el-checkbox v-if="scope.row.type == true" v-model="scope.row.is_allow"></el-checkbox>
                 </template>
               </el-table-column>
               <el-table-column label="非限制员工" min-width="300">
                 <template slot-scope="scope">
                   <span class="select" v-if="scope.row.is_select==true" @click="show_box(scope.row)">{{scope.row.select}}</span>
                 </template>
               </el-table-column>
               <el-table-column prop="remark" label="配置说明" width="300">
                 <template slot-scope="scope">
                   <span class="remark">{{scope.row.remark}}</span>
                 </template>
               </el-table-column>
            </el-table>
          </div>
      </el-tab-pane>
      <el-tab-pane label="必填项配置" name="second">
        <!-- 必填项配置 -->
          <div class="billing_table">
            <el-table id="classifyTable" :data="data_must" height="100%" border style="width: 100%">
              <!-- 索引 -->
              <el-table-column type="index" width="50"></el-table-column>
              <!-- 操作 -->
              <el-table-column label="操作" width="300">
                <template slot-scope="scope">
                  <i class="el-icon-edit-outline icon" title="修改"></i>
                  <i class="el-icon-delete icon" title="删除"></i>
                </template>
              </el-table-column>
              <el-table-column label="必填" width="100">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.ismust"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="名称" sortable  min-width="300"></el-table-column>
            </el-table>
          </div>
      </el-tab-pane>
      <el-tab-pane label="编码设置" name="third">
        <!-- 客户分类编码个性化设置(统一设置) -->
        <div class="setting_classBox">
          <div class="settingTable">
            <table class="system_table">
              <thead>
                <tr>
                  <th width="300">客户编号前缀</th>
                  <th width="180px">编号中段格式</th>
                  <th width="100px">顺序号长度</th>
                  <th width="150px">示例</th>
                  <th width="100px">分类设置</th>
                  <th>说明</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="input_td">
                    <el-input v-model="input" size="mini" placeholder="请输入内容"></el-input>
                  </td>
                  <td class="input_td">
                    <el-input v-model="input" size="mini" placeholder="请输入内容"></el-input>
                  </td>
                  <td class="input_td">
                    <el-input v-model="input" size="mini" placeholder="请输入内容"></el-input>
                  </td>
                  <td>

                  </td>
                  <td class="center_td">
                    <el-checkbox v-model="checked"></el-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- 客户分类编码个性化设置(分类设置) -->
        <div class="setting_classBox">
          <div class="settingTable">
            <table class="system_table">
              <thead>
                <tr>
                  <th width="150px">客户分类</th>
                  <th width="80px">编号前缀</th>
                  <th width="80px">编号中段格式</th>
                  <th width="80px">顺序号长度</th>
                  <th width="100px">示例</th>
                  <th width="100px">公海库规则</th>
                  <th width="100px">公海分类</th>
                  <th width="100px">客户信息规范</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    客户分类1
                  </td>
                  <td class="input_td">
                    <el-input v-model="input" size="mini" placeholder="请输入内容"></el-input>
                  </td>
                  <td class="input_td">
                    <el-input v-model="input" size="mini" placeholder="请输入内容"></el-input>
                  </td>
                  <td class="input_td">
                    <el-input v-model="input" size="mini" placeholder="请输入内容"></el-input>
                  </td>
                  <td class="input_td">
                    <el-input v-model="input" size="mini" placeholder="请输入内容"></el-input>
                  </td>
                  <td class="input_td">
                    <el-input v-model="input" size="mini" placeholder="请输入内容"></el-input>
                  </td>
                  <td class="input_td">
                    <el-input v-model="input" size="mini" placeholder="请输入内容"></el-input>
                  </td>
                  <td class="input_td">
                    <el-input v-model="input" size="mini" placeholder="请输入内容"></el-input>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 员工选择组件 -->
    <personnel-list @SelectedData="getPersonnelData" :defaultSelected="defaultUserSelected"></personnel-list>
    <!-- 客户分类选择组件 -->
    <customer-tree @SelectedData="getCustomerData" :defaultSelectedData="defaultSelectedData" :defaultSelectedClassfiy="defaultSelectedClassfiy"></customer-tree>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions,
  } from 'vuex'
  //导入请求方法
  //导入方法
  import req from "@/network/hr/organization"
  import net from '@/network/system/dictionary.js'
  import api from "@/network/system/controls"
  //导入组件
  import customerTree from '@/components/commComponent/tree/tree_customerClassfiy'; //客服分类选择

  export default{
    name:'setting_finance',
    data(){
      return{
        //测试用
        radio:'',
        checked:'',
        input:'',
        activeName:'first',
        loading:'',//loading加载框
        currentObject:{},//当前选择的对象
        defaultUserSelected:[],//负责人默认选中
        defaultSelectedClassfiy:[],//客户id默认选中
        defaultSelectedData:[],//客户默认选中对象填充
        visibleData:[],
        oldData:[,,,,,,,,,,,,,],//修改之前数据数组
        newData:[,,,,,,,,,,,,,],//修改之后数据数组
        FollowupSetId:[],//跟进用户排除员工数组
        excludeCustomerOne:[],//未联系客户归入公海库的天数的排除员工
        excludeCustomerTwo:[],//未成交客户加入公海库的天数的排除员工
        excludeCustomerThree:[],//成交客户未联系加入公海库的天数的排除员工
        excluder_id:'',//排除员工方法Id
        marketingId:'',//营销人员id
        //基本配置
        baseInfo:[
          {
            id:'145',
            name:'允许不同员工创建重复客户',
            enabel:false,
            is_select:false,
            type:true,
            is_allow:false,
          },
          {
            id:'14118',
            name:'员工最多领取公海客户日数量',
            count:'',
            enabel:false,
            is_select:false,
          },
          {
            id:'36588',
            name:'员工最多领取公海客户月数量',
            count:'',
            enabel:false,
            is_select:false,
          },
          {
            id:'20369',
            name:'未联系客户归入公海库的天数',
            count:'',
            remark:' 注：选择的员工不受该规则限制',
            enabel:false,
            select:'请排除员工',
            select_type:1,
            exclude_type:1,
            is_select:true
          },
          {
            id:'20360',
            name:'未成交客户加入公海库的天数',
            count:'',
            is_enable:false,
            enabel:true,
            remark:' 注：选择的员工不受该规则限制',
            select:'请排除员工',
            select_type:1,
            exclude_type:2,
            is_select:true
          },
          {
            id:'20370',
            is_enable:false,
            name:'成交客户未联系加入公海库的天数',
            enabel:true,
            count:'',
            remark:' 注：选择的员工不受该规则限制',
            select:'请排除员工',
            select_type:1,
            exclude_type:3,
            is_select:true
          },
          {
            id:'',
            is_enable:false,
            name:'未跟进的客户提醒跟进',
            enabel:true,
            count:'',
            remark:' 注：选择的员工不会提醒',
            is_select:true,
            exclude_type:4,
            select_type:1,
            select:'请排除员工',
          },
          {
            id:'80234',
            name:'每个业务人员每月最多锁定客户数',
            enabel:false,
            count:'',
            is_select:false,
            remark:' 注：锁定客户不受公海库规则限制'
          },
          {
            id:'80235',
            name:'锁定客户的期限',
            enabel:false,
            count:'',
            is_select:false,
            remark:' 注：超过30天自动释放锁定'
          },
          {
            name:'指定运营人员',
            enabel:false,
            is_select:true,
            input:true,
            select:'选择运营人员',
            select_type:2,
            exclude_type:5,
            remark:' 注：运营人员只能设置一个(多选取第一个)'
          },

          {
            name:'只营销的客户分类',
            enabel:false,
            is_select:true,
            input:true,
            select_type:3,
            select:'请选择客户分类',
          },
        ],
        // 必填字段
        data_must:[
          {
            name:'企业全称',
            ismust:false,
          },
          {
            name:'联系人姓名',
            ismust:false,
          },
          {
            name:'联系人邮箱',
            ismust:false,
          },
          {
            name:'所在国家',
            ismust:false,
          },
          {
            name:'首次联系',
            ismust:false,
          },
          {
            name:'联系人电话',
            ismust:false,
          },
          {
            name:'省份/州/区',
            ismust:false,
          },
          {
            name:'城市',
            ismust:false,
          },
          {
            name:'企业简称',
            ismust:false,
          },
          {
            name:'手机',
            ismust:false,
          },
          {
            name:'职位',
            ismust:false,
          },
          {
            name:'传真',
            ismust:false,
          },
          {
            name:'网址',
            ismust:false,
          },
          {
            name:'地址',
            ismust:false,
          },
        ],
      }
    },
    created() {
      this.getData()
    },
    mounted(){
    },
    computed:{
      ...mapState({
        CustomerClassfiy:state => state.system.CustomerClassfiy,//部门数据
        personnelData:state => state.hr.personnelData,//企业所有员工信息
      })
    },
    watch:{
       baseInfo:{
         deep:true,
         handler(newVal){
           newVal.forEach(item=>{
             this.visibleData.forEach(obj=>{
                if(item.id == "20360" && obj.id == "20358"){
                     obj.para_value = item.is_enable ? '1' : '0'
                }
                if(item.id == '20370' && obj.id == '20359'){
                     obj.para_value = item.is_enable ? '1' : '0'
                }
             })
           })
         },
       }
    },
    methods:{
      ...mapMutations([
        'SHOW_ALLPERSONNELBOX', //控制负责人选择弹框是否显示
        'SHOW_CUSTOMERCLASSFIYBOX',//控制客户分类弹框
        'SHOW_PERSONNELBOX',
        'PERSONNELDATA'//负责人数据
      ]),
      ...mapActions([
          'getCustomerClassify',//获取客户分类
      ]),
      async getData(){
        this.loading = this.commonJsExtend.customLoading("#customer",3);
        await this.getPersonnelInfo();//员工信息
        await this.getAllcompanyInfo();//企业参数配置
        await this.getExcludeInfo();//排除信息
        await this.getFollowupSet();//跟进信息
        await this.getClassfiyInfo();//客户分类
        this.loading.close()
      },
      /* 获得选择的员工 */
      getPersonnelData(data){
        console.log(data);
        if(this.currentObject.select_type == 1){//选取排除 员工
          if(this.currentObject.exclude_type == 1){
            this.excludeCustomerOne = []
            data.forEach(item=>{
              this.excludeCustomerOne.push(item.user_id)
            })
          }else if(this.currentObject.exclude_type == 2){
            this.excludeCustomerTwo = []
            data.forEach(item=>{
              this.excludeCustomerTwo.push(item.user_id)
            })
          }else if(this.currentObject.exclude_type == 3){
            this.excludeCustomerThree = []
            data.forEach(item=>{
              this.excludeCustomerThree.push(item.user_id)
            })
          }else if(this.currentObject.exclude_type == 4){
            this.FollowupSetId = []
            data.forEach(item=>{
              this.FollowupSetId.push(item.user_id)
            })
          }
          // console.log(this.excludeCustomerOne);
          // console.log(this.excludeCustomerThree);
          // console.log(this.FollowupSetId);
          let arrLenght = data.length
          this.currentObject.select = `已选择${arrLenght}个员工`
        }else if(this.currentObject.select_type == 2){//选取运营人员
            this.marketingId = data[0].user_id
           this.currentObject.select = data[0].user_name
        }
      },
      /* 获得选择的客户分类 */
      getCustomerData(data){
        this.defaultSelectedClassfiy = []
        // console.log(data);
        data.forEach(item=>{//默认选中
          this.defaultSelectedClassfiy.push(item.id)
        })
        let arrLenght = data.length
        this.currentObject.select = `已选择${arrLenght}个客户分类`
      },
      /* 获取企业所有配置信息 */
      async getAllcompanyInfo(){
        await api.getAllcompanyInfo().then(res=>{
          if(res.code == 200){
            this.baseInfo.forEach(item=>{
              res.data.forEach(obj=>{
                if(item.id == '20360' && obj.id == '20358'){//判断未成交客户加入公海库天数是否启用
                  item.is_enable = obj.para_value==1 ? true : false
                }
                if(item.id == '20370' && obj.id == '20359'){//判断成交客户未联系加入公海库的天数是否启用
                  item.is_enable = obj.para_value==1 ? true : false
                }
                if(item.id == obj.id){//获取限制数
                  if(obj.id == '145'){
                    this.$set(item,'para_id',obj.para_id)
                    item.is_allow = obj.para_value==1 ? true : false
                  }else{
                    this.$set(item,'para_id',obj.para_id)
                    item.count = obj.para_value
                  }
                }
              })
            })
            res.data.forEach(item=>{
              if(item.id == '15446'){
                this.oldData[1] = item.para_value
                this.visibleData.push(item)
              }else if(item.id == '11530'){
                 this.oldData[3] = item.para_value
                this.visibleData.push(item)
              }else if(item.id == '36586' ){
                 this.oldData[2] = item.para_value
                this.visibleData.push(item)
              }else if(item.id == '14120'){
                this.oldData[9] = item.para_value
                this.visibleData.push(item)
              }else if(item.id == '36587'){
                this.oldData[4] = item.para_value
                this.visibleData.push(item)
              }else if(item.id == '14117'){
                this.oldData[5] = item.para_value
                this.visibleData.push(item)
              }else if(item.id == '85762'){
                this.visibleData.push(item)
              }else if(item.id == '86193'){
                this.visibleData.push(item)
              }else if(item.id == '20358'){
                this.oldData[10] = item.para_value
                this.visibleData.push(item)
              }else if(item.id == '20359'){
                 this.oldData[12] = item.para_value
                this.visibleData.push(item)
              }else if(item.id == '14118'){
                this.oldData[6] = item.para_value
              }else if(item.id == '36588'){
                this.oldData[7] = item.para_value
              }else if(item.id == '20369'){
                this.oldData[8] = item.para_value
              }else if(item.id == '20360'){
                this.oldData[11] = item.para_value
              }else if(item.id == '20370'){
                this.oldData[13] = item.para_value
              }else if(item.id == '145'){
                this.oldData[0] = item.para_value
              }else if(item.id == "80234"){
                this.oldData[14] = item.para_value
              }else if(item.id == "80235"){
               this.oldData[15] = item.para_value
              }
            })
          }
        })
      },
      /* 获取排除信息 */
      async getExcludeInfo(){
        await api.getExcludeInfo().then(res=>{
          if(res.code == 200){
            this.excluder_id = res.data.id
            let excluder_one = res.data.public_excluder_one.split(",")//未联系客户归入公海库的天数的排除员工
            this.excludeCustomerOne = res.data.public_excluder_one.split(",")
            let excluder_two = res.data.public_excluder_two.split(",")//未成交客户加入公海库的天数的排除员工
            this.excludeCustomerTwo = res.data.public_excluder_two.split(",")
            let excluder_three = res.data.public_excluder_three.split(",")//成交客户未联系加入公海库的天数的排除员工
            this.excludeCustomerThree = res.data.public_excluder_three.split(",")
            this.baseInfo.forEach(item=>{
              if(item.id == '20369'){
                if(excluder_one[0] == ""){
                    item.select = "请排除员工"
                }else{
                  let arr = []
                 excluder_one.forEach(item=>{
                   this.personnelData.forEach(obj=>{
                     if(item == obj.user_id){
                       // console.log(item,obj.user_id);
                       arr.push(obj)
                     }
                   })
                 })
                 this.$set(item,'excluder',arr)
                 let arrLenght = excluder_one.length
                  item.select = `已选择${arrLenght}个员工`
                }

              }else if(item.id == '20360'){
                if(excluder_two[0] == ""){
                    item.select = "请排除员工"
                }else{
                 let arr = []
                excluder_two.forEach(item=>{
                  this.personnelData.forEach(obj=>{
                    if(item == obj.user_id){
                      // console.log(item,obj.user_id);
                      arr.push(obj)
                    }
                  })
                })
                this.$set(item,'excluder',arr)
                let arrLenght = excluder_two.length
                item.select = `已选择${arrLenght}个员工`
                }
              }else if(item.id == '20370'){
                if(excluder_three[0] == ""){
                    item.select = "请排除员工"
                }else{
                   let arr = []
                  excluder_three.forEach(item=>{
                    this.personnelData.forEach(obj=>{
                      if(item == obj.user_id){
                        // console.log(item,obj.user_id);
                        arr.push(obj)
                      }
                    })
                  })
                  this.$set(item,'excluder',arr)
                  let arrLenght = excluder_three.length
                  item.select = `已选择${arrLenght}个员工`
                }
              }
            })
            // console.log(res.data);
          }
        })
      },
      /* 获取配置信息 */
      async getFollowupSet(){
       await api.getFollowupSet().then(res=>{
          if(res.code == 200){
            this.baseInfo.forEach(item=>{
              if(item.name == '未跟进的客户提醒跟进'){
                item.id = res.data.id
                item.is_enable = res.data.is_enable==1 ? true : false
                item.count = res.data.set_day
                this.$set(item,'excluder',res.data.exclude_user)
                this.FollowupSetId = res.data.exclude_user.split(",")
                let exclude = res.data.exclude_user.split(",")
                if(exclude[0]!=''){
                  let arrLenght = exclude.length
                  item.select = `已选择${arrLenght}个员工`
                }else{
                  item.select = `请排除员工`
                }
              }
            })
            // console.log(res.data);
          }
        })
      },
      handleClick(){

      },
      /* 保存更新数据 */
      updateData(){
        if(Number(this.baseInfo[1].count) > Number(this.baseInfo[2].count)){
          this.$message({
            type:'error',
            message:'最多领取客户日数量不能大于月数量',
            duration:3000,
          })
          return false
        }
        let followUp = {}//跟进配置数据
        // console.log(this.oldData.join(","));
        this.baseInfo.forEach(item=>{
          if(item.name == "未跟进的客户提醒跟进"){
            followUp = item
          }
           if(item.id == '14118'){
            this.newData[6] = item.count
          }else if(item.id == '36588'){
            this.newData[7] = item.count
          }else if(item.id == '20369'){
            this.newData[8] = item.count
          }else if(item.id == '20360'){
            this.newData[11] = item.count
          }else if(item.id == '20370'){
            this.newData[13] = item.count
          }else if(item.id == '145'){
            this.newData[0] = item.is_allow ? 1 : 0
          }else if(item.id == "80234"){
            this.newData[14] = item.count
          }else if(item.id == "80235"){
            this.newData[15] = item.count
          }
        })
        this.visibleData.forEach(item=>{
          if(item.id == '15446'){
            this.newData[1] = item.para_value
          }else if(item.id == '11530'){
             this.newData[3] = item.para_value
          }else if(item.id == '36586' ){
             this.newData[2] = item.para_value
          }else if(item.id == '14120'){
            this.newData[9] = item.para_value
          }else if(item.id == '36587'){
            this.newData[4] = item.para_value
          }else if(item.id == '14117'){
            this.newData[5] = item.para_value
          }else if(item.id == '20358'){
            this.newData[10] = item.para_value
          }else if(item.id == '20359'){
            this.newData[12] = item.para_value
          }
        })
        console.log(this.newData.join(","));

        let sSysParamatersValue = []
        this.baseInfo.forEach(item=>{
          if(item.hasOwnProperty("id")){
            if(item.id == '145'){
            let obj = {
              id: Number(item.id),
              para_value:item.is_allow ? 1 : 0,
              para_id:item.para_id
            }
            sSysParamatersValue.push(obj)
            }else{
              let obj = {
                id:Number(item.id),
                para_value:item.count,
                para_id:item.para_id
              }
              sSysParamatersValue.push(obj)
            }

          }
        })
        this.visibleData.forEach(item=>{
          let obj = {
            id:item.id,
            para_value:item.para_value,
            para_id:item.para_id,
          }
          sSysParamatersValue.push(obj)
        })
        let oldvalue = '3###'+this.oldData.join(",")+'###'+this.newData.join(",")
        console.log(sSysParamatersValue);
        var data = {//企业级控制参数修改请求参数
          oldvalue,
          sSysParamatersValue:JSON.stringify(sSysParamatersValue)
        }
        console.log(followUp);
        var data1 = {//未跟进的客户提醒跟进请求参数

           id:followUp.id,
           is_enable:followUp.is_enable ? 1 : 0,
           set_day:followUp.count,
           exclude_user:this.FollowupSetId.join(",")
        }
        var data2 = {//排除员工的请求参数
          id:this.excluder_id,
          public_excluder_one:this.excludeCustomerOne.join(","),
          public_excluder_two:this.excludeCustomerTwo.join(","),
          public_excluder_three:this.excludeCustomerThree.join(",")
        }
        api.updateSysParame(data).then(res=>{//企业级控制参数修改请求
          if(res.code == 200){
            this.$message({
              type:'success',
              message:'保存修改成功'
            })
           this.getAllcompanyInfo()
          }else{
            this.$message({
              type:'error',
              message:res.message,
            })
          }
        })
        api.updateFollowupSet(data1).then(res=>{//未跟进的客户提醒跟进请求
          if(res.code == 200){
          }else{
            this.$message({
              type:'error',
              message:res.message,
            })
          }
        })
        api.updateExcludeInfo(data2).then(res=>{//排除员工的请求
          if(res.code == 200){
          }else{
            this.$message({
              type:'error',
              message:res.message,
            })
          }
        })
        api.addMarketing({upid:this.marketingId}).then(res=>{//指定运营人员
          if(res.code == 200){
          }else{
            this.$message({
              type:'error',
              message:res.message,
            })
          }
        })
        let ids = this.defaultSelectedClassfiy.join(",")
        // console.log(ids);
        api.addClassfiyYunXiao({ids:ids}).then(res=>{//营销客户分类
          if(res.code == 200){
          }else{
            this.$message({
              type:'error',
              message:res.message,
            })
          }
        })
      },
      show_box(data){
        if(data.hasOwnProperty("is_enable")){
          if(data.is_enable == false){
            this.$message({
              type:'error',
              message:'未启用,不可选择排除员工'
            })
            return false
          }
        }
        this.currentObject = data
        if(data.select_type==1){
          if(data.name == "未跟进的客户提醒跟进"){
           this.defaultUserSelected = []
          let IdArr = data.excluder.split(",")
          let arr = []
          // console.log(IdArr);
          // console.log(this.personnelData);
          IdArr.forEach(item=>{
            this.personnelData.forEach(obj=>{
              if(item == obj.user_id){
                // console.log(item,obj.user_id);
                arr.push(obj)
              }
            })
          })
          this.defaultUserSelected = arr
          }else{
            if(data.excluder != ""){
              this.defaultUserSelected = data.excluder.slice(0)
            }
          }
          console.log(this.defaultUserSelected);
          this.SHOW_ALLPERSONNELBOX(true)
        }else if(data.select_type==2){
          this.SHOW_ALLPERSONNELBOX(true)
        }else if(data.select_type==3){
          this.SHOW_CUSTOMERCLASSFIYBOX(true)
        }
      },
      /* 获取企业所有员工信息*/
      async getPersonnelInfo(){
        // 发送请求
        // this.loading=this.commonJsExtend.customLoading("#personnelTable",3)
       await req.getAllUserData().then(res=>{
          if(res.length>0){
            //将企业员工信息存入vuex
            this.baseInfo.forEach(item=>{
              res.forEach(obj=>{
              if(item.name == "指定运营人员" && obj.is_yunying == '1'){
                  this.marketingId = obj.user_id
                  this.$set(item,'operate',obj.user_id)
                  item.select = obj.user_name
              }
            })
            })

            this.PERSONNELDATA(res);
            //将企业员工信息存入当前页面(使用不同方式存方式,)
          }
        })
      },
      getClassfiyInfo(){
        net.customerDict.getCustomerClassfiy().then(res => {
          if (res.code == 200) {
              this.defaultSelectedClassfiy = []
              res.data.forEach(obj=>{
                if(obj.is_yingxiao == '1'){
                  this.defaultSelectedData.push(obj)
                  this.defaultSelectedClassfiy.push(obj.id)
                }
              })
              this.baseInfo.forEach(item=>{
                if(item.name == "只营销的客户分类"){
                  let arrLenght = this.defaultSelectedClassfiy.length
                  item.select = item.select = `已选择${arrLenght}个客户分类`
                }
              })
          }
        })
      },
    },
    components:{
      customerTree,
    }
  }
</script>
<style scoped>
  .button{
    margin-bottom: 10px;
  }
  .remark{
    color: red;
  }
  .select{
    color: #0099FF;
    font-size: 14px;
    cursor: pointer;
  }
</style>
