<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
    技术支持：Tech@21gmail.com
    单元名称：客户分类弹框组件
    开始时间：2021-05-07
    开发人员：万攀
    最后修改：2021-05-07
    备注说明：如需修改请联系开发人员
  -->
  <template>
    <div class="">
      <el-dialog title="客户分类" :visible.sync="show_customerClassfiyBox" :before-close="colse_departmentBox" width="400px" append-to-body>
        <div class="department_Box">
          <el-tree :data="CustomerClassfiy"
                    node-key="id"
                   :props="defaultProps"
                   :default-checked-keys="defaultSelectedClassfiy"
                   default-expand-all
                   show-checkbox
                   :check-strictly="true"
                   @check-change="SelectDepartment">
          </el-tree>
        </div>
        <!-- 确认取消 -->
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" size="mini" @click="commit_department">确认</el-button>
          <el-button size="mini" @click="cancel_department">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </template>

  <script>
    // 导入vuex
    import {mapMutations,mapState,mapActions} from 'vuex'

    export default {
      name:'tree_department',
      data() {
        return {
          // Element ui相关
          defaultProps: {//匹配接口字段信息
            children: 'children',
            label: 'name'
          },
          SelectData:[],//选中的数据
        };
      },
      props:{
        // 默认选中的数据
        defaultSelectedClassfiy:{
          type:Array,
          default(){
            return []
          }
        },
        defaultSelectedData:{
          type:Array,
          default(){
            return []
          }
        }
      },
      watch:{
        defaultSelectedData:{
          deep:true,
          handler(newVal){
            this.SelectData = newVal
          }
        }
      },
      created() {
        this.judjeCustomerClassfiy()
      },
      computed:{
        ...mapState({
          show_customerClassfiyBox: state => state.commComponent.show_customerClassfiyBox,//控制显示客户选择弹框组件
          CustomerClassfiy:state => state.system.CustomerClassfiy,//部门数据
        }),
      },
      methods: {
        ...mapMutations([
          'SHOW_CUSTOMERCLASSFIYBOX',//控制单据选择弹框是否显示
        ]),
        ...mapActions([
          'getCustomerClassify',
        ]),
        /* 判断vuex是否存在数据*/
        judjeCustomerClassfiy(){
          // 无数据则发送请求
          if(!this.CustomerClassfiy.length>0){
            // 获取部门信息
            this.getCustomerClassify();
          }
        },
        /* 获取选中的部门 */
        SelectDepartment(row,is_check,is_child){
          if(is_check){
              this.SelectData.push(row)
              // console.log(this.SelectData);
          }else{
            this.SelectData.forEach((item,index)=>{
              if(item.id == row.id){
                this.SelectData.splice(index,1)
              }
            })
          }
        },
        /* 关闭弹框 */
        colse_departmentBox(){
          this.SHOW_CUSTOMERCLASSFIYBOX(false);
        },

        // 提交选择部门
        commit_department(){
          this.$emit('SelectedData',this.SelectData)
          this.SHOW_CUSTOMERCLASSFIYBOX(false);
        },
        // 取消选择部门
        cancel_department(){
          this.SHOW_CUSTOMERCLASSFIYBOX(false);
        },
      }
    };
  </script>

  <style lang="less" scoped="scoped">
    .department_Box{
      width: 100%;
      height: 300px;
      overflow: auto;
      // border: 1px solid black;
    }
  </style>
