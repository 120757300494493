<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：上传配置
开始时间：2021-08-21
开发人员：康泽鑫
最后修改：2021-08-21
备注说明：如需修改请联系开发人员
-->
<template>
  <div>
    <!-- 配置内容 -->
    <div class="setting_contentBox billing_table" v-if="isPower">
      <el-table id="classifyTable" :data="mesSettingData" height="100%" border
        style="width: 100%">
        <el-table-column prop="name" sortable label="参数名称" width="400"></el-table-column>
        <el-table-column prop="setting" label="参数设置" width="500">
          <template slot-scope="scope">
            <!-- 单选按钮 -->
            <div v-if="scope.row.show_type=='radio'">
              <el-radio v-for="(item,index) in scope.row.setting" v-model="scope.row.para_value" :label="index"
                :disabled="isDisabled" @change="updateParam(scope.row)">{{item.name}}</el-radio>
            </div>
            <!-- 设置企业用户 -->
            <span class="per" v-if="scope.row.state == 0">
              <span>指定员工: </span>
              <span @click="showUserBox(scope.row)">{{scope.row.para_value}}</span>
            </span>
            <!-- 设置企业客户 -->
            <span class="per" v-if="scope.row.state == 1">
              <span>指定客户: </span>
              <span @click="showUserBox(scope.row.state)">{{scope.row.para_value}}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="配置说明">
          <template slot-scope="scope">
            <span class="explain">{{scope.row.explain}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 负责人选择组件 -->
    <personnel-list @SelectedData="getPersonnelData" :defaultSelected="defaultSelected" v-if="show_userbox"></personnel-list>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions,
  } from 'vuex'

  //导入方法
  import api from "@/network/system/controls"

  //导入组件
  import personnelList from '@/components/commComponent/list/list_personnel'; //负责人列表组件

  export default{
    name:'setting_mes',
    data(){
      return{
        //界面数据
        isPower:true,//判断是否有查询权限
        show_userbox:false,//控制显示用户弹框组件
        curEditParaId:"",//当前编辑的参数id
        isDisabled:false,//是否可编辑
        mesSettingData:[//生产配置数据
          {
            type: '生产单据',
            para_id: '353',
            para_value: 0,
            name: '生产计划单严格按照bom清单执行',
            show_type:'radio',
            setting: [{
                id: 0,
                name: '启用',
              },
              {
                id: 1,
                name: '不启用',
              },
            ],
            explain: ''
          },
          {
            type: '生产单据',
            para_id: '359',
            para_value: "未设置",
            is_check: true,
            state: 0,
            name: 'BOM清单/工序设置权限人',
            show_type:'setPersonal',
            explain: ''
          },
          {
            type: '生产单据',
            para_id: '360',
            para_value: "未设置",
            state: 0,
            show_type:'setPersonal',
            name: 'BOM/工序费用设置权限人',
            explain: ''
          },
          {
            type: '生产单据',
            para_id: '361',
            para_value: "未设置",
            state: 0,
            show_type:'setPersonal',
            name: '生产计划费用相关设置权限人',
            explain: ''
          },
        ],

        //子组件数据
        defaultSelected:[],//用户默认回显数据
      }
    },
    mounted() {
      this.initVuexData();
      //初始化界面数据
      this.getParamListData();
    },
    computed: {
      ...mapState({
        personnelData: state => state.hr.personnelData, //企业所有员工信息
        userPowerInfo: state => state.user.userPowerInfo, //权限
      })
    },
    methods:{
      ...mapMutations([
        'SHOW_CUSTOMERBOX', //控制客户选择弹框是否显示
        'SHOW_PERSONNELBOX', //控制显示负责人弹框
      ]),

      /* 初始化vuex数据 */
      async initVuexData() {
        //判断企业用户
        if (this.personnelData.length == 0) {
          this.getAllUserData();
        }
        //判断用户权限(需优化  这里先这样写  不知道为什么await没有用)
        if (this.userPowerInfo.length == 0) {
          await this.getUserPowerInfo();
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 1, 1, '查询')) {
            return
          }
          this.isPower = true
        } else {
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 1, 1, '查询')) {
            return
          }
          this.isPower = true
        }
      },

      /* 获取控制参数数据 */
      getParamListData(){},

      /* 显示用户弹框组件 */
      showUserBox(data){
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 1, 4, '修改')) {
          return
        }
        this.defaultSelected = [];
        //获取当前的操作参数id
        this.curEditParaId = data.para_id;
        //加载用户组件
        this.show_userbox = true;
        //显示员工选择弹框
        this.SHOW_PERSONNELBOX(true);
      },

      /* 用户组件返回参数 */
      getPersonnelData(data){
        //处理返回的用户数据
        let resUserMsg = this.commonJsExtend.getPersonnelMsg(data);
        console.log(resUserMsg);
        //获取当前
      },

      /* 修改参数方法 */
      updateParamValue(){
        let limitData = {
          id: data.id,//需要修改的参数id
          para_id: data.para_id,//
          para_value: "",
        }
        //获取日志信息
        data.logContent = logContent;
        //发送请求
        api.updateSysParametersValue(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '修改参数配置成功',
              duration: this.elDuration
            })
            this.getAllcompanyInfo(); //获取企业信息
            this.getCompanyParamList(); //刷新数据
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },
    },
    components:{
      personnelList
    }
  }
</script>

<style>
</style>
